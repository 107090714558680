import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
//import { useAuth } from '../../context/auth';

const PrivateRouteWithLayout = props => {
  const { layout: Layout, component: Component, ...rest } = props;
//  const { userAuthState } = useAuth();

  return (
    <Route
    
      {...rest}
      render={matchProps => (
        <Layout>
          <Component {...matchProps} />
        </Layout>
      )}
    />
    // {/* <Route
    //   {...rest}
    //   render={matchProps =>
    //     userAuthState.authTokens ? (
    //       <Layout>
    //         {/* {alert(
    //           'Private:' +
    //             userAuthState.user +
    //             ' token: ' +
    //             userAuthState.authTokens
    //         )} */}
    //         <Component {...matchProps} />
    //       </Layout>
    //     ) : (
    //         <Redirect
    //           to={{ pathname: '/log-in', state: { referer: props.location } }}
    //         />
    //       )
    //   }
    // /> */}
  );
};

PrivateRouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string
};
export default PrivateRouteWithLayout;