import { Button, Grid, MenuItem,TextField } from '@material-ui/core'
import { Autocomplete } from '@mui/material';
import React, { useEffect } from 'react'
import { useState,useContext } from 'react';
import DateFnsUtils from '@date-io/date-fns'; 
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from "axios";
import { useUrlContext } from '../context/urlContext';
import {
    
    MuiPickersUtilsProvider,
    DatePicker
} from '@material-ui/pickers';
import { errorContext } from "../Components/ErrorDisplay"

function ConsumerReport(props) {
    const { UrlHelperObj } = useUrlContext();
    const {handleError,setLoading,setErrorMessage} = useContext(errorContext);
    const[fromDate,setFromDate] = useState(null);
    const[toDate,setToDate] = useState(null);
    const[productValue,setProductValue]=useState([]);
    const[promoType,setPromoType] = useState(2);
    const[typeList,setTypeList]=useState([{id:2,name:"Gym"},{id:1,name:"Zoo"},{id:0,name:"General"}])
    const[productList,setProductList] = useState([]);//{id:2,name:"Gym"},{id:1,name:"Zoo"},{id:0,name:"General"}])
    const validationSchema = yup.object({
        
        valid_to:yup
            .string("AAA")
            .nullable()
            .when('type', {
                is: (type) => (type === '1' || type === '0'),
                then: yup.string().required("Valid to date is required").nullable()
            })
        });


        useEffect(()=>{
            // if(props.selectedBrand !== null){
            //    alert("GetProduct"+props.selectedBrand+"  "+promoType)
            //     getProductList(props.selectedBrand,promoType);
            // }
            //alert("Loaded");
            
        },[]);
        useEffect(()=>{
             if(props.selectedBrand !== null || props.selectedBrand !== undefined){
            //    alert("GetProduct"+props.selectedBrand+"  "+promoType)
                 getProductList(promoType);
             }
            //alert(props.selectedBrand);
            
        },[props.selectedBrand]);

        async function postFormData(data)
        {
            setLoading(true);
            //alert(JSON.stringify(data));
            await axios.post(UrlHelperObj.consumerReportUrl(),data,
            {responseType:'blob'
            })
            .then(response => response.data)
            .then(results => {console.log("Post data Consumer report");
                const url = window.URL.createObjectURL(new Blob([results]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "download.csv"); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
            .catch(err => handleError(err));
            setLoading(false);
        }
        const formik = useFormik({
            enableReinitialize:true,
            validationSchema: validationSchema,
            initialValues:{
                brand_id:props.selectedBrand,
                start_date: '',
                end_date: '',
                product_type:2,
                products:[]
            },
    
            onSubmit:(values)=>{
                
                if(values.brand_id === null || values.brand_id === 0)
                {
                    alert("Please select Issuing partner and brand");
                    return;
                }
                postFormData(values);
         
            },
         
        });
        useEffect(()=>{
            var arr=[];
            productValue.forEach(temp=>
                {arr.push(temp.id);}
            )
            //alert(JSON.stringify(arr))
            formik.setFieldValue("products",arr);
        },[productValue]);
        
        const handleChangeInPromotionType = (e) =>{
            if(props.selectedBrandId === null)
            {
                alert("Please select Partner and Brand before selecting products and types");
                return;
            }
            setPromoType(parseInt(e.target.value));
            formik.setFieldValue("product_type",parseInt(e.target.value));
            getProductList(parseInt(e.target.value));
        }
        async function getProductList(type)
        {
            await axios.get(UrlHelperObj.promotionUrl_GET(props.selectedBrand,type))
        .then(response => response.data)
        .then(results => setProductList(results))
        .catch(err => handleError(err));
        }
        function tenFunc(i){
            return(i<10?'0':'')+i;
        }
        function handleChangeFromDate(e)
        {
            setFromDate(e);
            var fromDateStr = e.getFullYear()+"-"+e.getMonth()+1+"-"+tenFunc(e.getDate());
            formik.setFieldValue("start_date",fromDateStr);
        }
        function handleChangeToDate(e)
        {
            setToDate(e);
            var toDateStr = e.getFullYear()+"-"+e.getMonth()+1+"-"+tenFunc(e.getDate());
            formik.setFieldValue("end_date",toDateStr);
        }
  return (
      //  xs={12} spacing={1} style={{ borderStyle: 'none', borderColor: 'green' }}
        //  direction="row">
    <form onSubmit={formik.handleSubmit}  >
        <Grid item container direction='row'spacing={1} xs={12} style={{ borderStyle: 'none', borderColor: 'green' }}>
      
            <Grid item xs={1} sm={1} md={1}></Grid>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Grid item xs={4} sm={4} md={4}  >
                                    <DatePicker
                                        fullWidth
                                        autoOk={true}
                                        inputVariant='outlined'
                                        id="valid_from"
                                        name="valid_from"
                                        label="Valid from date"
                                        value={fromDate}
                                        //disablePast
                                        
                                        format="dd/MM/yyyy"
                                        //value={formik.values.valid_from}
                                        //type="date"
                                        //disabled={(!isAddOperation) || (formik.values.type===2)}
                                        onChange={handleChangeFromDate}
                                        InputLabelProps={{ shrink: true }}  
                                        error={formik.touched.valid_from && Boolean(formik.errors.valid_from)}
                                        helperText={formik.touched.valid_from && formik.errors.valid_from}
                                    />
                                </Grid>
                                <Grid item xs={1} sm={1} md={1}></Grid>
                                <Grid item xs={4} sm={4} md={4}  >
                                    <DatePicker
                                        fullWidth
                                        autoOk={true}
                                        inputVariant='outlined'
                                        id="valid_to"
                                        name="valid_to"
                                        label="Valid To Date"
                                        //disabled={(!isAddOperation) || (formik.values.type===2)}
                                        value={toDate}
                                        onChange={handleChangeToDate}
                                        // value={formik.values.valid_to}
                                        // onChange={formik.handleChange}
                                        
                                        //disablePast
                                        format="dd/MM/yyyy"
                                        InputLabelProps={{ shrink: true }}  
                                        error={formik.touched.valid_to && Boolean(formik.errors.valid_to)}
                                        helperText={formik.touched.valid_to && formik.errors.valid_to}
                                    />
                                </Grid>
            </MuiPickersUtilsProvider>                      
            <Grid item xs={1} sm={1} md={2}></Grid>
            <Grid item xs={1} sm={1} md={1}></Grid>
            <Grid  item xs={4} sm={4} md={4}  >
                <TextField
                variant='outlined'
                fullWidth
                //disabled={!isAddOperation}
                select
                InputLabelProps={{ shrink: true }} 
                id="type"
                name="type"
                label="   Promotion Type"
                value={promoType}
                onChange={handleChangeInPromotionType}
                error={formik.touched.type && Boolean(formik.errors.type)}
                helperText={formik.touched.type && formik.errors.type}
                >
                    {typeList &&
                        typeList.map(type1 => (
                        <MenuItem value={type1.id} key={type1.id}>
                            {type1.name}
                        </MenuItem>
                        ))}
                </TextField>
            </Grid>
            
            <Grid item xs={1} sm={1} md={1}></Grid>
            <Grid item xs={4} sm={4} md={4}>
                <Autocomplete
                fullWidth
                multiple
                id="size-medium-outlined-multi"
                size="medium"
                value={productValue}
                onChange={(event: any, newValue: any | null) => {
                    //alert(JSON.stringify(productValue))
                    setProductValue(newValue.map(option => option.value || option));
                    }}
            isOptionEqualToValue={(option, value) => option.value === value}
            // getOptionLabel={(option) => {
            //   if (typeof option === 'number') {
            //     return productList.find(item => item.value === option)?.label;
            //   } else {
            //     return option.label;
            //   }
            // }}
                options={productList}
                getOptionLabel={(product) => product.name}
                defaultValue={[productList[0]]}
                renderInput={(params) => (
                <TextField variant='outlined' 
                {...params} 
                helperText={"Please select partner and brand to view promotions"}
                label="Promotions" placeholder="Promotions" />
                )}
            />
            </Grid>
        </Grid>
        <Grid item container direction='row'   style={{ borderStyle: 'none', borderColor: 'yellow' }} xs={12} justifyContent='center'>
                <Grid item xs={4} md={4} sm={4} style={{ textAlign:'center', borderStyle: 'none', borderColor: 'red' }}>
                    <Button type='submit' variant='contained' color='primary'> Submit</Button>
                </Grid>
        </Grid>
    </form>
    // </form>    
    // </Paper>
  )
}

export default ConsumerReport