import { Button, Grid, TextField,MenuItem } from '@material-ui/core';
import { useFormik } from 'formik';
import {React, useEffect, useState} from 'react'
import * as yup from 'yup';
import TimezoneSelect from 'react-timezone-select'

export default function PrinterMakeForm(props)
{
    
    const[isAddOperation,setIsAddOperation] = useState(false);
    const[recvValues,setRecvValues] = useState({ 
        id:'', 
        name:'',
        model:'',
        width_pix:'',
        width_inch:''
    });
    useEffect(()=>{

       if(props.selectedRow !== null )//&& valueLoaded === false)
       {
            
            setRecvValues({
                id:props.selectedRow.id,
                name:props.selectedRow.name,
                model:props.selectedRow.model,
                width_pix:props.selectedRow.width_pix,
                width_inch:props.selectedRow.width_inch
            });
            
            setIsAddOperation(false);
        }
        else
        {
            setRecvValues({ 
                id:'', 
        name:'',
        model:'',
        width_pix:'',
        width_inch:''
            });
            setIsAddOperation(true);
          
        }

    },[props.selectedRow]);
    
    const validationSchema = yup.object({
        name: yup
            .string("AAA")
          .required('Name is required'),
        model: yup
          .string("AAA")
        .required('model is required'),
        
      });
      function onCancelForm()
      {
          //alert("Cancel");
          props.handleSubmit(null,isAddOperation,true)
      }
   
    
    const formik = useFormik({
        enableReinitialize:true,
        validationSchema: validationSchema,
        initialValues:{
            id:recvValues?.id || '',
            name:recvValues?.name || '',
            model:recvValues?.model || '',
            width_inch:recvValues?.width_inch || '',
            width_pix:recvValues?.width_pix || '',
            
        },

        onSubmit:(values)=>{
        
            if(values.mac_id === '')
                props.handleSubmit(null,isAddOperation,true);
            else
            {

                props.handleSubmit(values,isAddOperation,false);
            }
           //this.resetForm();

        },
        // onReset:(values)=>{
        //     alert("Onreset");

        //     props.handleSubmit(null,isAddOperation,true)
        // }
    });
    
    return(
        // <Grid style={{ borderStyle: 'solid', borderColor: 'pink' }} container item spacing={3} xs={12}
        // direction="column" alignItems='flex-start' >
            // <Paper >
            <form onSubmit={formik.handleSubmit} >
                {/* <Grid container spacing={2}> */}
                    <Grid  item container xs={12} spacing={2}
                    justifyContent="space-around"  style={{ borderStyle: 'none', borderColor: 'black' }}
                    alignItems='center' direction="row">
                {/* <Grid container spacing={{xs:2,md:5}} direction="row" columns={{xs:4,sm:8,md:12}}> */}
                            <Grid item xs={12} sm={12} md={12}  >
                            </Grid>
                            <Grid item  xs={4} sm={4} md={5}  >
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    id="name"
                                    name="name"
                                    label="Name"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    error={formik.touched.name && Boolean(formik.errors.name)}
                                    helperText={formik.touched.name && formik.errors.name}
                                />
                            </Grid>
                            <Grid item  xs={4} sm={4} md={5}  >
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    id="model"
                                    name="model"
                                    label="Model"
                                    value={formik.values.model}
                                    onChange={formik.handleChange}
                                    error={formik.touched.model && Boolean(formik.errors.model)}
                                    helperText={formik.touched.model && formik.errors.model}
                                />
                            </Grid>
                            <Grid item  xs={4} sm={4} md={5}  >
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    id="width_inch"
                                    name="width_inch"
                                    label="width_inch"
                                    type="number"
                                    value={formik.values.width_inch}
                                    onChange={formik.handleChange}
                                    error={formik.touched.width_inch && Boolean(formik.errors.width_inch)}
                                    helperText={formik.touched.width_inch && formik.errors.width_inch}
                                />
                            </Grid>
                            <Grid item  xs={4} sm={4} md={5}  >
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    id="width_pix"
                                    name="width_pix"
                                    label="width pix"
                                    type="number"
                                    value={formik.values.width_pix}
                                    onChange={formik.handleChange}
                                    error={formik.touched.width_pix && Boolean(formik.errors.width_pix)}
                                    helperText={formik.touched.width_pix && formik.errors.width_pix}
                                />
                            </Grid>
                            
                            
                            
                            
                            

                    </Grid>
                    <Grid container xs={12}
                     direction="row" spacing={3}
                      style={{ borderStyle: 'none', borderColor: 'black' }} justifyContent='center'>
                        <Grid item >
                            <Button type='submit' color='primary' variant='contained'> submit </Button>
                        </Grid>
                        <Grid item>
                            <Button type='button' onClick={onCancelForm}  color='secondary' variant='contained'> cancel</Button>
                        </Grid>
                    </Grid>
                {/* </Grid> */}
            </form>
            // </Paper>
        // </Grid>


    );
}