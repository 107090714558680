import React,{useContext,useState}  from 'react';
import {CircularProgress, Box, Typography, Grid,Paper,Button,Step,StepLabel,Stepper } from "@material-ui/core";
import { createTheme  } from "@material-ui/core/styles";
import useStyles from '../theme/formCssStyles';
import RPartner from './RPartner';
import Location from './location';
import PrinterLocation from './PrinterLocation';
import { ThemeProvider } from '@material-ui/styles';
import { useHistory } from "react-router-dom";
import { errorContext } from '../Components/ErrorDisplay'
import ShellyPrinterTabs from './ShellyPrinterTabs';

const steps = ['Redemption Partners', 'Locations', 'Printer/Pax  & Shelly Lock'];

const theme = createTheme({
    overrides: {
    
     MuiStepIcon: {
      root: {
        '&$completed': {
          color: 'pink',
        },
        '&$active': {
          color: 'red',
        },
      },
      active: {},
      completed: {},
    },
   
  
  
    MuiStepLabel:{
        root:{
            color:'blue'
            // '&$active': {
            //     color: 'red',
            //   },  
        },
        active:{ '&$active': {
            color: 'red',
          },
        },
        },
    },    }
);
function RedemptionPartner( ) 
{
    const classes = useStyles();
    let history = useHistory();
    const [activeStep, setActiveStep] = useState(0);
    const [formShown,setFormShown] = useState(false);
    const [selectedRow,setSelectedRow] = useState(null);
    const [selectedRedemptionPartner,setSelectedRedemptionPartner] = useState(null);
    const [selectedLocation,setSelectedLocation] = useState(null);
    const [selectedPrinterLocation,setSelectedPrinterLocation] = useState(null);
    const {loading,errorMessage,setErrorMessage} = useContext(errorContext);
    
    function handleNext() {
        setErrorMessage(null);
        console.log("handleNext"+JSON.stringify(selectedRow));
        switch(activeStep)
        {
            case 0:
                if(selectedRedemptionPartner === null)
                {
                    alert("Please select Redemption partner before going for Location");
                    return;
                }
                break;
            case 1:
                if(selectedLocation === null)
                {
                    alert("Please select Location before going for Printer Location");
                    return;
                }
                break;
            case 2:
                
                    history.push("/");
                    break;
            default:
                break;
        }

        setActiveStep(prevActiveStep => prevActiveStep + 1);
    
        if (activeStep === steps.length - 1)
            console.log('next');
      }
      React.useEffect(()=>{
        //alert("selected row:"+JSON.stringify(selectedRow))
      },[selectedRow])
      function handleBack() {
        setErrorMessage(null);
        switch(activeStep)
        {
            case 2:
               // alert("2")
                setSelectedPrinterLocation(null);
                break;
            case 1:
                //alert("1")
                setSelectedLocation(null)
                break;
            case 0:
                //alert("0")
                    break;
            default:
                break;
        }

        setActiveStep(prevActiveStep => prevActiveStep - 1);
      }
    
      function handleReset() {
        setErrorMessage(null);
        setActiveStep(0);
        setSelectedRedemptionPartner(null);
        setSelectedLocation(null);
        setSelectedPrinterLocation(null);
      }
      function handleSetSelectedRow(row)
      {
        setSelectedRow(row);
        switch(activeStep)
        {
            case 0:
                {
                    setSelectedRedemptionPartner(row);
                    break;
                }
            case 1:
                {
                    setSelectedLocation(row);
                    break;
                }
            case 2:
                {
                    setSelectedPrinterLocation(row);
                    break;
                }
            default:
                break;
        }
            
        
      }
      function getSelectedNames()
      {
        var displayStr = "";
        if(selectedRedemptionPartner !== null)
            displayStr += "Selected Redemption Partner : "+selectedRedemptionPartner.name;
        if(selectedLocation !== null)
            displayStr += " / Selected Location : "+selectedLocation.name;
        if(selectedPrinterLocation !== null)
            displayStr += " / Selected Printer Location : "+selectedPrinterLocation.name;
        return displayStr;
      }
      function getStepContent(step) {
        switch (step) {
          // case 0:
          //   return <DisplayUnitDetailsForCBForm />;
             case 0:
                return (
                //   <h2>Partner</h2>
                
                <RPartner
                setSelectedRow={handleSetSelectedRow}
                setFormShown={setFormShown}
                alreadySelectedOwnRow={selectedRedemptionPartner}
                />
                );
            case 1:
                return(
                    <Location 
                    RedemptionPartnerRow={selectedRedemptionPartner}
                    setSelectedRow={handleSetSelectedRow}
                    setFormShown={setFormShown}
                    alreadySelectedOwnRow={selectedLocation}
                    />
                );
            case 2:
                return(
                    <ShellyPrinterTabs
                    LocationRow={selectedLocation}
                    setSelectedRow={handleSetSelectedRow}
                    setFormShown={setFormShown}
                    alreadySelectedOwnRow={selectedPrinterLocation}
                    />
                    // <PrinterLocation
                    // LocationRow={selectedLocation}
                    // setSelectedRow={handleSetSelectedRow}
                    // setFormShown={setFormShown}
                    // alreadySelectedOwnRow={selectedPrinterLocation}
                    // />
                );
         
            default:
                return "Unknown step";
            }
        }    
    
      
    return(
         <Box sx={{borderStyle:'none',borderColor:'green' }}>
            <Typography variant='h4' style={{color:'red', textAlign:'center' }}>Redemption Partner</Typography>
             {/* <Toolbar> */}
        <Grid container xs={12} direction="column">
            <Grid item xs={12} style={{borderColor:'red',borderStyle:'none'}}>
            <ThemeProvider theme={theme}>
                <Stepper
                    alternativeLabel
                    activeStep={activeStep}
                    style={{height:'100px'}}
                    className={classes.stepper}>
                    {steps.map(label => (
                        <Step key={label}
                        
                        >
                        
                        <StepLabel sx={{
                            
                            "& .MuiStepLabel-horizontal  .Mui-active": {
                              color: "red",
                            }
                         }} >{label}</StepLabel>
                        </Step>
                    ))}

                </Stepper>
                </ThemeProvider>        
            </Grid>
            <Grid item xs={12} style={{borderColor:'black',borderStyle:'none',textAlign:'center'}}>
                <p id="errorSpan" style={{height:'25px'}} >
                    {errorMessage}
                   
                  </p>
                  <Box style={{justifyContent:'center',alignItems:'center',zIndex:1001}}>
                    { loading && <CircularProgress variant='indeterminate' />}
                  </Box>
                  
             </Grid>

          
            <Grid
                item
                container
                direction="row"
                xs={12}
                spacing={1}
                style={{ borderStyle: 'none', borderColor: 'blue' }}
            >
                <Grid item container xs={12} direction="column">
                    {activeStep === steps.length ? (
                        <Grid item>
                            <Typography className={classes.instructions}>
                                All steps completed - you&apos;re finished
                            </Typography>
                            
                        </Grid>
                    ) : (
                        // <div>  {getStepContent(activeStep)}</div>
                        <Grid item container xs={12} style={{
                            borderStyle: 'none',
                            borderColor: 'green',
                            height:"300px"
                            }} direction="column" >
                            
                          
                                {getStepContent(activeStep)}
                          
                        </Grid>
                    )}
                </Grid>
                <Grid item xs={12} direction="row">
                    <Paper>
                        {getSelectedNames()}
                    </Paper>
                </Grid>
                <Grid item justifyContent='center' container style={{ display:formShown?'none':''}}>
                    {/* <Paper className={classes.paper2}> */}
                    {activeStep === steps.length ? (
                        <Grid item style={{ borderStyle: 'none', borderColor: 'green'}} container direction="row">
                            
                                <Grid item style={{}}>
                                    <Button onClick={handleReset} className={classes.buttons}>
                                    Reset
                                </Button>
                                </Grid>
                        </Grid>
                            ) : (
                        <Grid item style={{ borderStyle: 'none', borderColor: '',height:'40px' }} 
                         justifyContent='center' alignItems='center'
                                             container direction="row">
                                <Grid item>
                                    <Button
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    variant="contained"
                                    color='primary'
                                    className={classes.button}
                                    >
                                    
                                    Back
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                    variant="contained"
                                    onClick={handleNext}
                                    color="primary"
                                    className={classes.button}
                                    >
                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                    </Button>
                                </Grid>
                                
                        </Grid>)}
                    {/* </Paper> */}
                 </Grid>
            </Grid>
        </Grid>
    </Box>
    );
}
export default RedemptionPartner;