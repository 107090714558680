
import  {  useState,useContext } from "react"

import BasicTable from "./BasicReactTable";
import FormHOC from "./FormHOC"
import { errorContext } from '../Components/ErrorDisplay'

export default function ParentComponent(props)
{
    const[isTableDisplay,setIsTableDisplay] = useState(true);
    const[selectedRow,setSelectedRow] = useState(null);
    const {setErrorMessage} = useContext(errorContext);

    const FormComponent = FormHOC(props.form);
      
    function handleSetSelectedRow(row) {
      setSelectedRow(row);
    }
    function handleSubmit(data,isAddOperation,isCancelOperation)
    {   
        setSelectedRow(null);
        setIsTableDisplay(true);
        props.setFormShownFlag(false);
        console.log("SUBMIT-"+JSON.stringify(data));
        props.handleFormSubmitFunc(data,isAddOperation,isCancelOperation);
    }  
    function addFunc()
    {
      setErrorMessage(null)
      setSelectedRow(null);
      setIsTableDisplay(false);
      console.log(isTableDisplay)
      props.setFormShownFlag(true);
      //props.addFunc();
    }
    
    function editFunc(row)
    {
      setErrorMessage(null)
      setSelectedRow(row);
      setIsTableDisplay(false);
      props.setFormShownFlag(true);
    }
   
    // function deleteFunc(row)
    // {
    //   setSelectedRow(row);
    //   props.deleteFunc(row);
    //   setIsTableDisplay(true);
    //   props.setFormShownFlag(false);
    // }
    
        // if(isTableDisplay===true)
        //     return (<BasicTable
        //         onAddClick = {addFunc}
        //         onEditClick = {editFunc}
        //         onDeleteClick = {props.deleteFunc}
        //         rows={props.data}
        //         columns={props.columns}
        //         setSelectedRow={props.setSelectedRow}
        //         setParentSelectedRow={handleSetSelectedRow}
        //             />);
        // else
        //       return(<div><FormComponent 
        //         ps={[
        //           {handleSubmit:handleSubmit,
        //           selectedRow:selectedRow}]
        //           } >
        //           </FormComponent></div>)
       // if(isTableDisplay===true)
            return (isTableDisplay?<BasicTable
                onAddClick = {addFunc}
                onEditClick = {editFunc}
                onDeleteClick = {props.deleteFunc}
                rows={props.data}
                columns={props.columns}
                setSelectedRow={props.setSelectedRow}
                setParentSelectedRow={handleSetSelectedRow}
                alreadySelectedOwnRow={props.alreadySelectedOwnRow}
                isAddDisabled={props.isAddDisabled}
                    />:<FormComponent 
                ps={[
                  {handleSubmit:handleSubmit,
                  selectedRow:selectedRow,
                  IssuingPartnerRow:props.selectedIssuingPartnerRow,
                  BrandRow:props.selectedBrandRow,
                  RedemptionPartnerRow:props.selectedRedemptionPartnerRow,
                  LocationRow:props.selectedLocationRow,
                  progress:props.progress}]
                  } >
                  </FormComponent>)
        
        

}