import React, { useEffect } from "react";

import { useState,useContext } from "react";
import { useUrlContext } from '../context/urlContext';


import ParentComponent from "../Components/ParentForTableAndFormComponent";
import axios from "axios";
import LocationForm from "../Forms/LocationForm";
import { errorContext } from "../Components/ErrorDisplay";

export default function Location(props) {
    const { UrlHelperObj } = useUrlContext();
    const {handleError,setErrorMessage} = useContext(errorContext);
    const[selectedRedemptionPartner,setSelectedRedemptionPartner] = useState(null);
    const [columnInfo, setColumnInfo] = useState([
        {title:'Id',dbname:'id'},
        {  title: 'Name',dbname:'name' }, 
        {  title: 'Print Valid Coupon',dbname:'print_valid_coupon' }, 
        {  title: 'Print InValid Coupon',dbname:'print_invalid_coupon' }, 
    ]);
    const[locationRecords,setLocationRecords]=useState([]);
    
    
    useEffect(()=>{setSelectedRedemptionPartner(props.RedemptionPartnerRow);
    },[props.RedemptionPartnerRow]);

    useEffect(()=>{getLocationRecords()},[selectedRedemptionPartner]);

    function handleLocationRecords(results){
        console.log(results);
        if(results.length === 0)
            setErrorMessage("No record found");
        setLocationRecords(results);
    }

    async function getLocationRecords()
    {
    
        await axios.get(UrlHelperObj.locationUrl_GET(selectedRedemptionPartner.id))
        .then(response => response.data)
        .then(results => setLocationRecords(results))
        .catch(err => handleError(err));
    }
    async function deleteLocationRecord(record)
    {
        await axios.delete(UrlHelperObj.locationUrl(record))
        .then(response => response.data)
        .then(results =>console.log("delete of Location "+record+" successful"))
        .catch(err => handleError(err));
        getLocationRecords();
    }
    async function editLocationRecord(record)
    {
        const data = {
            id:record.id,
            name:record.name,
            redemption_partner_id:record.redemption_partner_id,
            print_valid_coupon:record.print_valid_coupon,
            print_invalid_coupon:record.print_invalid_coupon
        };
        console.log("Location edit"+JSON.stringify(record));
        await axios.put(UrlHelperObj.locationUrl(),data)
        .then(response => response.data)
        .then(results =>console.log("Edit of Location "+record+" successful"))
        .catch(err => handleError(err));
        getLocationRecords();
    }
    async function addLocationRecord(record)
    {
           //     name:record.partnerName,
        //     parent_wholesale_brand:record.partnerWholesaleBrandName,
        //     retailer_brand_name:record.retailerBrandName,
        //     location:record.location,
        //     key_contacts:record.contact,
        //     image:record.image
        // };

        // const data = new FormData();
        // data.append("id",0);
        // data.append("name",record.brandName);
        // data.append("issuer_id",record.issuerId);
        const data={
            id:0,
            name:record.name,
            redemption_partner_id:record.redemption_partner_id,
            print_valid_coupon:record.print_valid_coupon,
            print_invalid_coupon:record.print_invalid_coupon
        };
        
        await axios.post(UrlHelperObj.locationUrl(),data,)
        .then(response => response.data)
        .then(results =>console.log("Add of Location "+record+" successful"))
        .catch(err => handleError(err));
        getLocationRecords();
    }
  
    useEffect(() =>{
        //setTableFlag(true);
        getLocationRecords();
    },[])

    function handleFormSubmit(data,isAddOptr,isCancelOptr)
    {
        
        props.setFormShown(false);
        if(isCancelOptr === true)
        {
            console.log("Cancelled optr");
            return 0;
        }
        
        if(isAddOptr === true)
            addLocationRecord(data);
        else
            editLocationRecord(data);
    }
    
    
    return(
    
        <ParentComponent 
        data={locationRecords}
        columns={columnInfo}
        form={LocationForm}
        handleFormSubmitFunc={handleFormSubmit}
        setFormShownFlag={props.setFormShown}
        deleteFunc={deleteLocationRecord}
        setSelectedRow={props.setSelectedRow}
        selectedRedemptionPartnerRow={props.RedemptionPartnerRow}
        alreadySelectedOwnRow={props.alreadySelectedOwnRow}
        isAddDisabled={false}
        />
    );
            
}

