import * as React from 'react';
import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow,Paper, Button, Grid, 
  IconButton,TablePagination}  from "@material-ui/core"


import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import AddBoxIcon from '@mui/icons-material/AddBox';
import useStyles from '../theme/formCssStyles';
import { boolean } from 'yup';


export default function BasicTable(props) {
    // React.useState<readonly string[]>([]);
    const classes = useStyles();
    const [selected, setSelected] = React.useState(null);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [page, setPage] = React.useState(0);

    React.useEffect(()=>{
      props.columns.map(col => console.log(col.col));
      console.log(props.columns);
      console.log(props.rows);
    },props.columns)

    React.useEffect(()=>{
      if(props.alreadySelectedOwnRow != null)
      {
        setSelected(props.alreadySelectedOwnRow);
      }
    },[props.alreadySelectedOwnRow])

    const isSelected = (name: string) => 
    {
      if(selected === null)
        return false;
      if((selected !== null) || (selected !== undefined))
      {
        if(selected.id===name)
          return true;
        else
          return false;
      }
      
    };
    const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
    };
    const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.rows.length) : 0;

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
    React.useEffect(()=>{
     // if(selected !== null)
     // alert("Selected in table"+selected.name)
    },[selected]);
    function handleEdit(event,name){
      const selectedIndex = props.rows.find(x => x.name===name);//selected.indexOf(name);
      
      props.onEditClick(selectedIndex);
    }
    function handleDelete(event,name){
      const selectedIndex = props.rows.find(x => x.name===name);//selected.indexOf(name);
      
      props.onDeleteClick(selectedIndex.id);
    }
    const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
      const selectedIndex = props.rows.find(x => x.id===name);//selected.indexOf(name);
      // let newSelected: readonly string[] = [];
  
     //  alert(selectedIndex+name+selected);
      // if (selectedIndex === -1) {
      //   newSelected = newSelected.concat(selected, name);
      // } else if (selectedIndex === 0) {
      //   newSelected = newSelected.concat(selected.slice(1));
      // } else if (selectedIndex === selected.length - 1) {
      //   newSelected = newSelected.concat(selected.slice(0, -1));
      // } else if (selectedIndex > 0) {
      //   newSelected = newSelected.concat(
      //     selected.slice(0, selectedIndex),
      //     selected.slice(selectedIndex + 1),
      //   );
      // }
      //alert("index:"+JSON.stringify(  selectedIndex)+"  row:"+props.rows[selectedIndex]);
      props.setSelectedRow(selectedIndex);
      props.setParentSelectedRow(selectedIndex);
      setSelected(selectedIndex);
      
    };
    function getColumnData(row,col)
    {
        if(typeof(row[col.dbname]) === "boolean")
        {
          return (row[col.dbname]?"true":"false");
        } 
        else 
        return row[col.dbname];
    }
    function getRowCellHtml(row,col)
    {
      if(col.dbname ==='name')
      {
          return(    <TableCell  component="th" scope="row"  id={row.id} align="center">
          {row.name}
          </TableCell>);
      }
      else if(col.dbname === "auth_key")
      {
        return(  <TableCell style={{wordWrap:'break-word',maxWidth:'300px',display:'inline-block'}}  align="center"><div >{getColumnData(row,col)}</div></TableCell>);
      }
      else
      return(  <TableCell  align="center"><div >{getColumnData(row,col)}</div></TableCell>);
    }
    return (
      // <Grid xs={12}  container direction='row'>
      // <Grid item xs={12}>
      <div sx={{overflow:'auto'}}>
        <TableContainer >
          <div><IconButton align="right" disabled={props.isAddDisabled} onClick={props.onAddClick}><AddBoxIcon/></IconButton></div>
          <Table sx={{ minWidth: 650 }}  aria-label="simple table" size='small' >
            <TableHead>
              <TableRow  >
                <TableCell> </TableCell>
                <TableCell> </TableCell>
                {props.columns && 
                props.columns.map(col => (
                  col.dbname==="auth_key"?(
                  <TableCell   key={col.title} align='center' >
                    <span style={{"fontWeight":"bolder"}}>
                    {col.title} </span></TableCell>):(
                  <TableCell   key={col.title} align='center' >
                    <span style={{"fontWeight":"bold"}}>
                    {col.title} </span></TableCell>)
                ))
                }
            </TableRow>
            </TableHead>
            <TableBody>
                
              {props.rows && props.rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                
                 
                <TableRow  
                onClick={(event) => handleClick(event, row.id)}
                  role="checkbox"
                  style={{ backgroundcolor: isSelected(row.id)? 'pink':'green'}}
                  aria-checked={isSelected(row.id)}
                          tabIndex={-1}
                          key={row.name}
                          selected={isSelected(row.id)}
                  
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell style={{margin:'auto',maxWidth:'10px' ,border:'none',borderColor:'blue'}} ><IconButton aria-label='delete' onClick={(event)=>(handleDelete(event,row.name))}><DeleteIcon/></IconButton></TableCell>
                  <TableCell  style={{margin:'auto', maxWidth:'10px' ,border:'none',borderColor:'blue'}}><IconButton aria-label='Edit' onClick={(event)=>handleEdit(event,row.name)}><EditIcon/></IconButton></TableCell>
                  {props.columns && props.columns.map(col =>
                      getRowCellHtml(row,col)
                    
                  )}
                  {/* <TableCell component="th" scope="row" id={index}> */}
                
                  
                </TableRow>
              ))}
               {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 33*emptyRows//(dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={props.rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
      //   </Grid>
      // </Grid>
    );
  }
  