import { Button, Grid, TextField,Box, FormLabel } from '@material-ui/core';
import { useFormik } from 'formik';
import {React, useEffect, useState} from 'react'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';

import * as yup from 'yup';

export default function LocationForm(props)
{
     
    const[isAddOperation,setIsAddOperation] = useState(false);
    const[recvValues,setRecvValues] = useState({ id:'', name:'',print_valid_coupon:false,
    print_invalid_coupon:false,
    created_at:'',
   
    });
    useEffect(()=>{

       if(props.selectedRow !== null )//&& valueLoaded === false)
       {
            console.log("Location Edit"+JSON.stringify(props.selectedRow));
            setRecvValues({
                id:props.selectedRow.id,
                name:props.selectedRow.name,
                redemption_partner_id:props.RedemptionPartnerRow.id,
                print_valid_coupon:props.selectedRow.print_valid_coupon,
                print_invalid_coupon:props.selectedRow.print_invalid_coupon,
            });
            
            setIsAddOperation(false);
        }
        else
        {
            setRecvValues({  id:'',name:'',
            redemption_partner_id:props.RedemptionPartnerRow.id,
            print_valid_coupon:false,
            print_invalid_coupon:false,
            });
            setIsAddOperation(true);
        }

    },[props.selectedRow]);
    
    const validationSchema = yup.object({
        name: yup
            .string("AAA")
          .required('name is required'),
        
        
      });
      function onCancelForm()
      {
          //alert("Cancel");
          props.handleSubmit(null,isAddOperation,true)
      }
   
    //   const handleChange = (event) => {
    //     setRecvValues({
    //       ...recvValues,
    //       [event.target.name]: event.target.checked,
    //     });
    //   };
    
    const formik = useFormik({
        enableReinitialize:true,
        validationSchema: validationSchema,
        initialValues:{
            id:recvValues?.id || '',
            name:recvValues?.name || '',
        redemption_partner_id:props.RedemptionPartnerRow.id,
            print_valid_coupon:recvValues?.print_valid_coupon || false,
            print_invalid_coupon:recvValues?.print_invalid_coupon || false
        },

        onSubmit:(values)=>{
           // alert("on submit");
            if(values.name === '')
                props.handleSubmit(null,isAddOperation,true);
            else
            {

                props.handleSubmit(values,isAddOperation,false);
            }
           //this.resetForm();

        },
        // onReset:(values)=>{
        //     alert("Onreset");

        //     props.handleSubmit(null,isAddOperation,true)
        // }
    });

    return(
        // <Grid style={{ borderStyle: 'solid', borderColor: 'pink' }} container item spacing={3} xs={12}
        // direction="column" alignItems='flex-start' >
            // <Paper >
            <form onSubmit={formik.handleSubmit} >
                {/* <Grid container spacing={2}> */}
                    <Grid  item container xs={12} spacing={2}
                    justifyContent="space-around"  style={{ borderStyle: 'none', borderColor: 'black' }}
                    alignItems='center' direction="row">
                {/* <Grid container spacing={{xs:2,md:5}} direction="row" columns={{xs:4,sm:8,md:12}}> */}
                            <Grid item xs={12} sm={12} md={12}  >
                            </Grid>
                            <Grid item  xs={4} sm={4} md={5}  >
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    id="name"
                                    name="name"
                                    label="Name"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    error={formik.touched.name && Boolean(formik.errors.name)}
                                    helperText={formik.touched.name && formik.errors.name}
                                />
                            </Grid>
                            <Grid item xs={6} sm={4} md={5}>
                                {/* <FormControl> */}
                                <FormLabel component="legend">Print coupon options</FormLabel>
                                {/* <Box style={{borderBlockStyle:"solid",borderColor:'black'}}> */}
                                <FormGroup>
                                <FormControlLabel 
                                    control={<Checkbox  onChange={formik.handleChange} checked={formik.values.print_valid_coupon}
                                    value={formik.values.print_valid_coupon} name="print_valid_coupon" />} label="Print Valid Coupons" />
                                <FormControlLabel  
                                    control={<Checkbox onChange={formik.handleChange} checked={formik.values.print_invalid_coupon}
                                    value={formik.values.print_invalid_coupon} name="print_invalid_coupon"/>} label="Print Invalid Coupons" />
                                
                                </FormGroup>
                                {/* </Box> */}
                                
                                {/* </FormControl> */}

                                
                            </Grid>
                            
                            

                    </Grid>
                    <Grid container xs={12}
                     direction="row" spacing={3}
                      style={{ borderStyle: 'none', borderColor: 'black' }} justifyContent='center'>
                        <Grid item >
                            <Button type='submit' color='primary' variant='contained'> submit </Button>
                        </Grid>
                        <Grid item>
                            <Button type='button' onClick={onCancelForm}  color='secondary' variant='contained'> cancel</Button>
                        </Grid>
                    </Grid>
                {/* </Grid> */}
            </form>
            // </Paper>
        // </Grid>


    );
}