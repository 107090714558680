import React from "react";
import { AppBar,Toolbar,Link } from '@material-ui/core';
import useStyles from '../theme/formCssStyles';
import DrawerComponent from "../Components/Drawer";
import { makeStyles } from '@material-ui/core/styles';


const useStyles2 = makeStyles((theme)=>({
    // root: {
    //     //display: "flex",
    //      minHeight: "100vh",
    //      zIndex: 1,
    //      position: "relative",
    //      overflow: "hidden",
    //      backgroundColor: theme.palette.background.default
    //   },

      toolbar:theme.mixins.toolbar,
      toolbarActions: {
        marginLeft: "auto"
      },
      // Styles for the content area. It fills the available space
      // in the flex container to the right (or left) of the drawer.
      appContent: theme.mixins.gutters({
        flex: "1 1 100%", // https://github.com/philipwalton/flexbugs#flexbug-17
        maxWidth: "100%", // https://github.com/philipwalton/flexbugs#flexbug-17
        paddingTop: 10, // equal to AppBar height + 16px
        //margin: "0 auto",
        marginLeft:235,
        marginTop:20,
        marginRight:'auto',
        marginBottom:'auto',
        borderColor: 'red',
        borderStyle:'solid',
        //paddingLeft:300,
        // Set the max content width for each breakpoint
        // Content will be centered in the space to the right/left of drawer
        [theme.breakpoints.up("lg")]: {
          maxWidth: theme.breakpoints.values.lg
         }
      }),
      
}));
 function MainLayout(props)
{
    const classes1 = useStyles();
    const classes = useStyles2();
    const { children } = props;
    return(
        <div >
            {/* className={classes.root} */}
        <AppBar className={classes1.appBar}  >
            {/* <CssBaseline/> */}
            <Toolbar >
            {/* className={classes.logo} */}
                {/* <Typography variant="title" >OmniQpon</Typography> */}
                <Link href="/" color="inherit" variant="h5">OmniQpon</Link>
                </Toolbar>
        </AppBar>
        
        <DrawerComponent/>
        <main className={classes.appContent}>
             {children}
        </main>
        </div>

    );
}
export default MainLayout;