import React, {  useReducer } from "react";
import { createContext } from 'react';

import { AppReducer } from "./AppReducer";
const initialValue={
    errorMessage:'',
    loading:false
}
export const errorContext = createContext(initialValue);
export  const ErrorDisplayProvider = ({children})=>{
    const[state,dispatch] = useReducer(AppReducer,initialValue);

    function handleError(err)
    {
        setLoading(false);
        if(err.response)
        {
            console.log(err.response);
            setErrorMessage("Error-"+err.response.data.message);
        }
        else if(err.request)
        {
            console.log(err.request);
        }
    }
    function setErrorMessage(msg)
    {
        dispatch({
            type: 'SET_ERR',
            payload: msg
        });
    }
    function setLoading(flag)
    {
        console.log("SETLOADING"+flag);
        dispatch({
            type: 'SET-FLAG',
            payload:flag
        })
    }
    return(
        <errorContext.Provider value={{errorMessage:state.errorMessage,loading:state.loading,
        setErrorMessage,setLoading,handleError}}>
            {children}
        </errorContext.Provider>
    );
}