import React from 'react';
 
export const AppReducer = (state, action) => {
   switch(action.type) {
       case 'SET_ERR':
           return {
            ...state,errorMessage:action.payload
            
           }
       case 'SET-FLAG':
        console.log(state.loading);
        
           return {
            
            ...state,loading:action.payload
           }
       default:
           return state;
   }
}