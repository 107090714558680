import React, { useContext, useEffect } from "react";

import { useState } from "react";
import { useUrlContext } from '../context/urlContext';
//import BasicTable from "../Components/BasicReactTable";

import ParentComponent from "../Components/ParentForTableAndFormComponent";
import axios from "axios";
import RPartnerForm from "../Forms/RPartnerForm";
import { date } from "yup";
import { errorContext } from "../Components/ErrorDisplay";


function RPartner(props) {
    const { UrlHelperObj } = useUrlContext();
    const [columnInfo, setColumnInfo] = useState([
        {title: 'Name','dbname':'name'},
        {title:'WholeSale brand Name',dbname:'parent_wholesale_brand'},
        {title:'Retailer brand Name',dbname:'retailer_brand_name'},
        
        {title:'Contacts',dbname:'key_contacts'},
    ]);
    const[rpartnerRecords,setRpartnerRecords]=useState([]);
    const {handleError} = useContext(errorContext);
    

    async function getRPartnerRecords()
    {
        await axios.get(UrlHelperObj.rpartnerUrl())
        .then(response => response.data)
        .then(results => setRpartnerRecords(results))
        .catch(err => handleError(err));
    }
    async function deleteRPartnerRecord(record)
    {
        await axios.delete(UrlHelperObj.rpartnerUrl(record))
        .then(response => response.data)
        .then(results =>console.log("delete of Partner "+record+" successful"))
        .catch(err => handleError(err));
        getRPartnerRecords();
    }
    async function editRPartnerRecord(record)
    {
        // const data = {
        //     id:record.id,
        //     name:record.partnerName,
        //     parent_wholesale_brand:record.partnerWholesaleBrandName,
        //     retailer_brand_name:record.retailerBrandName,
        //     location:record.location,
        //     key_contacts:record.contact,
        //     logo:record.logo
        // };
        const data ={
            id:record.id,
            name:record.redemptionPartnerName,
            parent_wholesale_brand:record.partnerWholesaleBrandName,
            retailer_brand_name:record.retailerBrandName,
            key_contacts:record.contact

        }

        console.log("Partner edit"+JSON.stringify(record));
        await axios.put(UrlHelperObj.rpartnerUrl(),data)
        .then(response => response.data)
        .then(results =>console.log("Edit of RPartner "+record+" successful"))
        .catch(err => handleError(err));
        getRPartnerRecords();
    }
    async function addRPartnerRecord(record)
    {
           //     name:record.partnerName,
        //     parent_wholesale_brand:record.partnerWholesaleBrandName,
        //     retailer_brand_name:record.retailerBrandName,
        //     location:record.location,
        //     key_contacts:record.contact,
        //     image:record.image
        // };

        const data ={
            id:0,
            name:record.redemptionPartnerName,
            parent_wholesale_brand:record.partnerWholesaleBrandName,
            retailer_brand_name:record.retailerBrandName,
            key_contacts:record.contact

        }
       /* data.append("id",0);
        data.append("name",record.redemptionPartnerName);
        data.append("parent_wholesale_brand",record.partnerWholesaleBrandName);
        data.append("retailer_brand_name",record.retailerBrandName)
        
        data.append("key_contacts",record.contact);
        */
        await axios.post(UrlHelperObj.rpartnerUrl(),data)
        .then(response => response.data)
        .then(results =>console.log("Add of RPartner "+record+" successful"))
        .catch(err => handleError(err));
        getRPartnerRecords();
    }
    useEffect(() =>{
        //setTableFlag(true);
        getRPartnerRecords();
    },[])

    function handleFormSubmit(data,isAddOperation,isCancelOperation)
    {
        props.setFormShown(false);
        if(isCancelOperation === true)
        {
            console.log("Cancelled operation");
            return 0;
        }
        console.log("handleFormSubmit"+data+"-add-operation-"+isAddOperation);
        if(isAddOperation === true)
            addRPartnerRecord(data);
        else
            editRPartnerRecord(data);
    }
    
    
    return(
    
        <ParentComponent 
        data={rpartnerRecords}
        columns={columnInfo}
        form={RPartnerForm}
        handleFormSubmitFunc={handleFormSubmit}
        setFormShownFlag={props.setFormShown}
        alreadySelectedOwnRow={props.alreadySelectedOwnRow}
        deleteFunc={deleteRPartnerRecord}
        setSelectedRow={props.setSelectedRow}
        isAddDisabled={false}
        />
    );
            
}
export default RPartner;