import React,{useContext,useEffect,useState}  from 'react';
import { Box,Tabs,Tab,Typography} from "@material-ui/core";

import { createTheme  } from "@material-ui/core/styles";
import useStyles from '../theme/formCssStyles';
import RPartner from './RPartner';
import Location from './location';
import PrinterLocation from './PrinterLocation';
import { ThemeProvider } from '@material-ui/styles';
import { useHistory } from "react-router-dom";
import { errorContext } from '../Components/ErrorDisplay'
import ShellyLock from './ShellyLock';

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tab-tabpanel-${index}`}
        aria-labelledby={`simple-tab-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
function ShellyPrinterTabs(props)
{
    const classes = useStyles();
    let history = useHistory();

    const[value,setValue] = useState(0);

    useEffect(()=>{
        console.log(props);
    },[props])
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        
      };
    function a11yProps(index: number) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
      }
      

    return(
        <div>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
    <Tab label="Printers" {...a11yProps(0)} />
    <Tab label="Shelly Lock"  {...a11yProps(1)} />
    
  </Tabs>
</Box>
<TabPanel value={value} index={0}>
                 <PrinterLocation
                     LocationRow={props.LocationRow}
                     setSelectedRow={props.setSelectedRow}
                     setFormShown={props.setFormShown}
                     alreadySelectedOwnRow={props.alreadySelectedOwnRow}
                    />
</TabPanel>
<TabPanel value={value} index={1}>
                <ShellyLock
                     LocationRow={props.LocationRow}
                     setSelectedRow={props.setSelectedRow}
                     setFormShown={props.setFormShown}
                     alreadySelectedOwnRow={props.alreadySelectedOwnRow}
                    />
</TabPanel>

</div>);
    }


export default ShellyPrinterTabs;