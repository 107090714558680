import React, { useEffect } from "react";

import { useState,useContext } from "react";
import { useUrlContext } from '../context/urlContext';


import ParentComponent from "../Components/ParentForTableAndFormComponent";
import axios from "axios";
import PromotionForm from "../Forms/PromotionForm";

import { errorContext } from "../Components/ErrorDisplay"

function Promotion(props) {
    const { UrlHelperObj } = useUrlContext();
    const {handleError,setLoading,setErrorMessage} = useContext(errorContext);
    const [columnInfo, setColumnInfo] = useState([
        {  title: 'Id',dbname:'id' },
        {  title: 'Name',dbname:'name' },
        // {title:'Brand Name',dbname:'brand_name'},
        // {title:'Description',dbname:'description'},
    //    {title:'Launch Date/Time',dbname:'launch_date_time'},
       {title:'Valid From Date',dbname:'valid_from'},
       {title:'Valid To Date',dbname:'valid_to'},
       {title:'Valid From Time',dbname:'valid_from_time_str'},
       {title:'Valid To Time',dbname:'valid_to_time_str'},
    //    {title:'Valid From Time',dbname:'valid_from_time'},
    //    {title:'Valid To Time',dbname:'valid_to_time'},
    //    {title:'Total Quantity',dbname:'total_qty'},
       {title:'Scans allowed',dbname:'num_scans_allowed'},
       {title:'Days allowed',dbname:'num_days_allowed'},
       //{title:'Program',dbname:'programid[name]'}
    //    {title:'Promotion Text',dbname:'promotion_text'},
    //    {title:'Instructions',dbname:'instructions'},
    {title:'Promotion Type',dbname:'typeStr'},
       {title:'Promotion Image',dbname:'image'},
       {title:'Program',dbname:'programid_name'},
       {title:'Tier',dbname:'tierid_name'}
    ]);
    const[selectedBrand,setSelectedBrand] = useState(null);
    const[promotionRecords,setPromotionRecords]=useState([]);
    
    
    
    useEffect(()=>{setSelectedBrand(props.BrandRow)
    },[props.BrandRow]);

    useEffect(()=>{
        if(selectedBrand === null || selectedBrand === undefined)
        {
            //setPromotionRecords(null);
            return;
        }
        console.log("SET-LOAD-true");
        setLoading(true);
        getPromotionRecords()},[selectedBrand]);

    // function toLocalTimeZone(utcDate,utcTime)
    // {
        
    //     const newDate = utcDate+"T"+utcTime;
    //     const d = new Date(newDate);
    //     //var lDate = d.toLocaleDateString();
    //     //var lTime = d.toLocaleTimeString();
    //     return d;//.toLocaleString();
    // }
    function tenFunc(i){
        return(i<10?'0':'')+i;
    }
    function toLocalDate(utcDate,utcTime)
    {
        if(utcDate === null)
            return "";
        const newDate = utcDate+" "+utcTime+"  UTC";
        const d = new Date(newDate);
        var monStr = tenFunc(d.getMonth()+1);
        //var localDateStr = tenFunc(d.getDate())+"/"+monStr +"/"+d.getFullYear();
        var localDateStr =d.getFullYear()+"-"+monStr+"-"+tenFunc(d.getDate());
                        
        
        var lDate = d.toLocaleDateString();
        var lTime = d.toLocaleTimeString();
        return localDateStr;
    }
    // function getLocalDateTime(utcDate,utcTime)
    // {
    //     const newDate = utcDate+" "+utcTime+"  UTC";
    //     return(new Date(newDate));
    // }
    function toLocalTime(utcDate,utcTime)
    {
        if(utcDate === null)
        {
            var tDate = new Date();
            utcDate = tDate.toLocaleDateString();
        }
            
        const newDate = utcDate+" "+utcTime+"  UTC";
        const d = new Date(newDate);
        var t = d.toLocaleTimeString();
        var t1 = t.substring(t.lastIndexOf(' ')+1);
        // for 12 hour time
        //var t2 = tenFunc(d.getHours())+":"+tenFunc(d.getMinutes())+" "+t1;

        // for 24 hour time
        var t2 = tenFunc(d.getHours())+":"+tenFunc(d.getMinutes());
        
        return t2;
        
    }
    function updatePromotionResults(results)
    {
        if(results.length === 0)
        {
            setErrorMessage("No Records found..");
        }
        
        results.forEach((values,key)=>{
          
            var timet = toLocalTime(values.valid_from,values.valid_from_time)
            var timet1 = toLocalTime(values.valid_to,values.valid_to_time)
        
            var fromDate = toLocalDate(values.valid_from,values.valid_from_time);
            var toDate = toLocalDate(values.valid_to,values.valid_to_time);

            values.valid_from_time_str = timet;
            values.valid_to_time_str = timet1;
            values.valid_from_date_str = fromDate;
            values.valid_to_date_str = toDate;

//           values.valid_from_time = getLocalDateTime(values.valid_from,values.valid_from_time);
//            values.valid_to_time = getLocalDateTime(values.valid_to,values.valid_to_time);
          
          var typeStr="";
          switch(values.type){
            case 2:
                typeStr="Gym";
                break;
            case 1:
                typeStr="Zoo";
                break;
            case 0:
                typeStr="General";
                break;
            default:
                typeStr="General";
                break;
          }
          values.typeStr = typeStr;
        })
        
        setPromotionRecords(results);
        //props.setLoading(false);
        console.log("SET-LOAD-false");
       setLoading(false);
    }
   /* function handleError(err)
    {
        setLoading(false);
        if(err.response)
        {
            console.log(err.response);
            setErrorMessage(err.response.data.message);
        }
        else if(err.request)
        {
            console.log(err.request);
        }
    }*/
    
    async function getPromotionRecords()
    {
        
        console.log(JSON.stringify(selectedBrand));    
        
        
        await axios.get(UrlHelperObj.promotionUrl_GET(selectedBrand.id)
        )
        .then(response => response.data)
        .then(results => updatePromotionResults(results))
        .catch(err => handleError(err));
    }
    async function deletePromotionRecord(record)
    {
        setLoading(true)
        await axios.delete(UrlHelperObj.promotionUrl(record))
        .then(response => response.data)
        .then(results =>console.log("delete of Partner "+record+" successful"))
        .catch(err => handleError(err));
        setLoading(false);
        getPromotionRecords();
    }
    async function editPromotionRecord(record)
    {
        //alert(JSON.stringify(record))
        const data = new FormData();
        data.append("id",record.id);
        data.append("name",record.name);
        data.append("brand_id",record.brand_id);
        data.append("description",record.description)
        
        data.append("valid_to",record.valid_to);
        data.append("valid_from",record.valid_from);
        data.append("valid_from_time",record.valid_from_time);
        data.append("valid_to_time",record.valid_to_time);
        data.append("num_days_allowed",record.num_days_allowed);
        data.append("num_scans_allowed",record.num_scans_allowed);
        data.append("promotion_text",record.promotion_text);
        data.append("instructions",record.instructions);
        data.append("programid",record.programid);
        data.append("tierid",record.tierid);
        data.append("image",record.image);
        data.append("type",record.type);
        data.append("timezone",Intl.DateTimeFormat().resolvedOptions().timeZone);
        
        await axios.put(UrlHelperObj.promotionUrl(),data,{headers: {
            'Content-Type': 'multipart/form-data'
         }})
        .then(response => response.data)
        .then(results =>console.log("Edit of Partner "+record+" successful"))
        .catch(err => handleError(err));
        getPromotionRecords();
    }
    async function addPromotionRecord(record)
    {
        setLoading(true);
        const data = new FormData();
        data.append("id",0);
        data.append("name",record.name);
        data.append("brand_id",record.brand_id);
        data.append("description",record.description)
        data.append("instructions",record.instructions);
        data.append("valid_to",record.valid_to);
        data.append("valid_from",record.valid_from);
        data.append("valid_from_time",record.valid_from_time);
        data.append("valid_to_time",record.valid_to_time);
        data.append("num_days_allowed",record.num_days_allowed);
        data.append("num_scans_allowed",record.num_scans_allowed);
        data.append("promotion_text",record.promotion_text);
        //data.append("total_qty",record.total_qty);
        data.append("programid",record.programid);
        data.append("tierid",record.tierid);
        data.append("image",record.image);
        data.append("type",record.type);
        data.append("timezone",Intl.DateTimeFormat().resolvedOptions().timeZone);
     
        
        await axios.post(UrlHelperObj.promotionUrl(),data,
        {headers: {
            'Content-Type': 'multipart/form-data'
         },
        
        })
        .then(response => response.data)
        .then(results => console.log("Add of Partner "+record+" successful"))
        .catch(err => handleError(err));
        setLoading(false);
        getPromotionRecords();
    }
    
    function handleFormSubmit(data,isAddOperation,isCancelOperation)
    {

        props.setFormShown(false);
        if(isCancelOperation === true)
        {
            console.log("Cancelled operation");
            return 0;
        }
        console.log("handleFormSubmit"+data+"-add-operation-"+isAddOperation);
        if(isAddOperation === true)
            addPromotionRecord(data);
        else
            editPromotionRecord(data);
    }
    
    
    return(
    
        <ParentComponent 
        data={promotionRecords}
        columns={columnInfo}
        form={PromotionForm}
        handleFormSubmitFunc={handleFormSubmit}
        setFormShownFlag={props.setFormShown}
        alreadySelectedOwnRow={props.alreadySelectedOwnRow}
        deleteFunc={deletePromotionRecord}
        setSelectedRow={props.setSelectedRow}
        selectedBrandRow={props.BrandRow}
        isAddDisabled={false}
        />
    );
            
}
export default Promotion;
