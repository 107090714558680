import React,{StrictMode} from 'react'
import { useContext,useState ,useEffect} from "react";
import { Radio,FormControlLabel,RadioGroup,Box, Typography, Grid,Paper,TextField,MenuItem,CircularProgress,FormControl,FormLabel, Button } from "@material-ui/core";
import { ThemeProvider } from '@material-ui/styles';
import { useHistory } from "react-router-dom";
import { errorContext } from '../Components/ErrorDisplay'
import { useUrlContext } from '../context/urlContext';
import axios from "axios";
import ConsumerReport from './ConsumerReport';
import ScanReport from './ScanReport';

export default function Report() {
    let history = useHistory();
    const { UrlHelperObj } = useUrlContext();
    const {loading,errorMessage,setErrorMessage} = useContext(errorContext);
    const {handleError} = useContext(errorContext);
    const [reportRadioValue,setReportRadioValue] = useState(1);
    
    const[partnerList,setPartnerList] = useState([]);
    const[brandList,setBrandList] = useState([]);
    const[selectedPartner,setSelectedPartner] = useState(null);
    const[selectedBrandId,setSelectedBrandId] = useState(null);
    const [reportTypes,setReportTypes ] = useState([{id:1,name:"Consumer Reports"},
                                                  {id:2,name:"Scan Reports"},
                                                  {id:3,name:"Product Reports"}])
    useEffect(()=>{
        getPartnerList();
        
        //getPromoLocationList(1);
    },[]);
    async function getPartnerList()
    {
        await axios.get(UrlHelperObj.partnerUrl())
        .then(response => response.data)
        .then(results => {setPartnerList(results)})
        .catch(err => handleError(err));
    }
    async function getBrandList(id)
    {
        await axios.get(UrlHelperObj.brandUrl_GET(id))
        .then(response => response.data)
        .then(results => {setBrandList(results)})
        .catch(err => handleError(err));
    }
    async function handleChangeInPartner(e)
    {
        setSelectedPartner(e.target.value);
        getBrandList(e.target.value);
    }
    function handleChangeInReportRadioValue(e)
    {
        setReportRadioValue(parseInt(e.target.value));
    }
    function GetFilterControls({radioValue}){
        
        if(radioValue === 1)
        {
            return(<ConsumerReport selectedBrand={selectedBrandId}  />);
        }
        else if(radioValue === 2)
        {
            return(<ScanReport selectedBrand={selectedBrandId}/>);
        }
        else if(radioValue === 3)
        {
            return(<p>This is not implemented currently</p>);
        }
    }    
    async function handleChangeInBrand(e)
    {
        e.preventDefault();
       // prevende
        setSelectedBrandId((e.target.value));
        //getPromotionList(e.target.value);
        
    }
    return(
        <Box sx={{borderStyle:'none',borderColor:'green' }}>
           <Typography variant='h4' style={{color:'red', textAlign:'center' }}>Reports</Typography>
            {/* <Toolbar> */}
        <Grid container xs={12} spacing={2} direction="column">
           <Grid item xs={12} style={{borderColor:'red',borderStyle:'none'}}>
              
           </Grid>
            <Grid item xs={12} 
                style={{borderColor:'black',borderStyle:'none',textAlign:'center'}}>
               <p id="errorSpan" style={{height:'25px'}} >
                   {errorMessage}
                  
                 </p>
                 <Box style={{justifyContent:'center',alignItems:'center',zIndex:1001}}>
                   { loading && <CircularProgress variant='indeterminate' />}
                 </Box>
                 
            </Grid>

            <Grid item justifyContent='space-around' xs={12} direction="row" 
               style={{ borderStyle: 'none', borderColor: 'blue' }} >
                   <Paper>
                   
                   <FormControl>
                        {/* <FormLabel id="demo-controlled-radio-buttons-group">Gender</FormLabel> */}
                        <RadioGroup row
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={reportRadioValue}
                            onChange={handleChangeInReportRadioValue}
                        >
                            {
                                  reportTypes.map(element => (
                                    <FormControlLabel 
                                    style={{marginRight:'100px',marginLeft:'100px'}} 
                                    value={element.id} control={<Radio />} label={element.name} />    
                                ))
                            }
                        </RadioGroup>
                        </FormControl>
                        
                   </Paper>
            </Grid>
            <Grid item container xs={12} style={{ borderColor:'blue',borderStyle:'none'}}>
           
            </Grid>
           <Grid
               item
               container
               direction="row"
               xs={12}
               spacing={1}
               style={{ borderStyle: 'none', borderColor: 'blue' }}
           >
               {/* <Grid item container xs={12}  
                style={{ borderStyle: 'solid', borderColor: 'blue' }}
                direction="row"> */}
                    <Grid item xs={1} sm={1} md={1} ></Grid>
                    <Grid item xs={4} sm={4} md={4}  >
                    <TextField 
                            fullWidth
                            variant='outlined'
                            select
                            id='selectedPartner'
                            name='selectedPartner'
                            value={selectedPartner}
                            label="Select Partner"
                            onChange={handleChangeInPartner}
                            >
                            {partnerList &&
                                partnerList.map(type => (
                                <MenuItem value={type.id} key={type.id}>
                                    {type.name}
                                </MenuItem>
                                ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} ></Grid>
                    <Grid item  xs={4} sm={4} md={4}  >
                        <TextField 
                            fullWidth
                            variant='outlined'
                            select
                            id='selectedBrandId'
                            name='selectedBrandId'
                            value={selectedBrandId}
                            label="Select Brand"
                            onChange={handleChangeInBrand}
                            >
                            {brandList &&
                                brandList.map(type => (
                                <MenuItem value={type.id} key={type.id}>
                                    {type.name}
                                </MenuItem>
                                ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={3} sm={3} md={1} ></Grid>
                    
               {/* </Grid> */}
               
               
               
           </Grid>
           {/* <Grid item container xs={12}  
                style={{ borderStyle: 'solid', borderColor: 'blue' }}
                direction="row"> */}
               <GetFilterControls radioValue={reportRadioValue}/>
               {/* </Grid> */}
           {/* <Grid item container direction='row' xs={12} justifyContent='center'>
                <Grid item xs={4} md={4} sm={4} style={{ textAlign:'center', borderStyle: 'none', borderColor: 'red' }}>
                    <Button variant='contained' color='primary'> Submit</Button>

                </Grid>
           </Grid> */}
       </Grid>
   </Box>
   );
}

//export default Report;