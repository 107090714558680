import React from "react"

const FormHOC = (...passedComponent) =>{

    

        
            return(props)=>(
                <React.Fragment>
                    {passedComponent.map((K,i)=> <K {...props.ps[i]} />)}
                    {/* <passedComponent {...this.props}/> */}
                </React.Fragment>
            );
        
    
}
export default FormHOC;