import React, { useContext, useEffect } from "react";

import { useState } from "react";
import { useUrlContext } from '../context/urlContext';
import {Paper,Typography} from '@material-ui/core'

import ParentComponent from "../Components/ParentForTableAndFormComponent";
import axios from "axios";
import PrinterMakeForm from "../Forms/PrinterMakeForm";
import { errorContext } from "../Components/ErrorDisplay";

export default function PrinterMake(props) {
    const { UrlHelperObj } = useUrlContext();
    const {handleError} = useContext(errorContext);
    const [formShown,setFormShown] = React.useState(false);
    const [selectedRow,setSelectedRow] = React.useState(null);
    const [columnInfo, setColumnInfo] = useState([
        {  title: 'Name',dbname:'name' },
        {title:"Model",dbname:"model"},
        {title:"Width (pix)",dbname:"width_pix"},
        {title:"Width (inches)",dbname:"width_inch"}
    ]);
    const[selectedPrinterMake,setSelectedPrinterMake] = useState(null);
    const[printerMakeRecords,setPrinterMakeRecords]=useState([]);
    
    // useEffect(()=>{setSelectedPrinterMake(props.LocationRow)
    // },[props.LocationRow]);

    useEffect(()=>{getPrinterMakeRecords()},[]);


    async function getPrinterMakeRecords()
    {
        await axios.get(UrlHelperObj.printerMakeUrl())
        .then(response => response.data)
        .then(results => setPrinterMakeRecords(results))
        .catch(err => handleError(err));
    }
    async function deletePrinterMakeRecord(record)
    {
        await axios.delete(UrlHelperObj.printerMakeUrl(record))
        .then(response => response.data)
        .then(results =>console.log("delete of Printer Make "+record+" successful"))
        .catch(err => handleError(err));
        getPrinterMakeRecords();
    }
    async function editPrinterMakeRecord(record)
    {
        
        console.log(JSON.stringify(record));
        await axios.put(UrlHelperObj.printerMakeUrl(),record)
        .then(response => response.data)
        .then(results =>console.log("Edit of printer make "+record+" successful"))
        .catch(err => handleError(err));
        getPrinterMakeRecords();
    }
    async function addPrinterMakeRecord(record)
    {
        console.log("ADD Printer RECORD:"+ record.name);
        
        record.id=0;
        
        await axios.post(UrlHelperObj.printerMakeUrl(),record)
        .then(response => response.data)
        .then(results =>console.log("Add of printer make "+record+" successful"))
        .catch(err => handleError(err));
        getPrinterMakeRecords();
    }
    useEffect(() =>{
        //setTableFlag(true);
        getPrinterMakeRecords();
    },[]);

    function handleFormSubmit(data,isAddOperation,isCancelOperation)
    {
        setFormShown(false);
        if(isCancelOperation === true)
        {
            console.log("Cancelled operation");
            return 0;
        }
        console.log("handleFormSubmit"+ JSON.stringify(data)+"-add-operation-"+isAddOperation);
        if(isAddOperation === true)
            addPrinterMakeRecord(data);
        else
            editPrinterMakeRecord(data);
    }
    
    
    return(
        <div>
            <Paper>
                <Typography variant="h4" style={{color:"red",textAlign:"center"}}>
                    Printer Make
                </Typography>
            </Paper>
        <ParentComponent 
        data={printerMakeRecords}
        columns={columnInfo}
        form={PrinterMakeForm}
        handleFormSubmitFunc={handleFormSubmit}
        setFormShownFlag={setFormShown}
        alreadySelectedOwnRow={props.alreadySelectedOwnRow}
        deleteFunc={deletePrinterMakeRecord}
        setSelectedRow={setSelectedRow}
        printerMakeRecords={printerMakeRecords}
        isAddDisabled={false}
        />
        </div>
    );
            
}

