import React, { useEffect } from "react";

import { useState,useContext } from "react";
import { useUrlContext } from '../context/urlContext';

import { errorContext } from "../Components/ErrorDisplay";
import ParentComponent from "../Components/ParentForTableAndFormComponent";
import axios from "axios";
import PrinterLocationForm from "../Forms/PrinterLocationForm";

export default function PrinterLocation(props) {
    const { UrlHelperObj } = useUrlContext();
    const [columnInfo, setColumnInfo] = useState([
        {  title: 'Mac Id',dbname:'mac_id' },
        {title:"Time Zone",dbname:"timezone"},
        {title:"Printer make",dbname:"printer_make_id"}
        
      
    ]);
    const[selectedLocation,setSelectedLocation] = useState(null);
    const[printerLocationRecords,setPrinterLocationRecords]=useState([]);
    const[isAddDisabled,setIsAddDisabled] = useState(true);
    const {handleError} = useContext(errorContext);

    useEffect(()=>{setSelectedLocation(props.LocationRow)
    },[props.LocationRow]);

    useEffect(()=>{getPrinterLocationRecords()},[selectedLocation]);

    useEffect(()=>{
        if(printerLocationRecords.length === 1)
            setIsAddDisabled(true);
        else
            setIsAddDisabled(false);

    },[printerLocationRecords]);

    async function getPrinterLocationRecords()
    {
        await axios.get(UrlHelperObj.printerlocationUrl_GET(selectedLocation.id))
        .then(response => response.data)
        .then(results => setPrinterLocationRecords(results))
        .catch(err => handleError(err));
    }
    async function deletePrinterLocationRecord(record)
    {
        await axios.delete(UrlHelperObj.printerlocationUrl(record))
        .then(response => response.data)
        .then(results =>console.log("delete of Printer Location "+record+" successful"))
        .catch(err => handleError(err));
        getPrinterLocationRecords();
    }
    async function editPrinterLocationRecord(record)
    {
        const data = {
            id:record.id,
            mac_id:record.mac_id,
            location_id:record.location_id,
            printer_make_id:record.printer_make_id,
            timezone:record.timezone
        }
        
        
        await axios.put(UrlHelperObj.printerlocationUrl(),data)
        .then(response => response.data)
        .then(results =>console.log("Edit of printer location "+record+" successful"))
        .catch(err => handleError(err));
        getPrinterLocationRecords();
    }
    /*function handleError(err)
    {
        setLoading(false);
        if(err.response)
        {
            console.log(err.response);
            setErrorMessage(err.response.data.message);
        }
        else if(err.request)
        {
            console.log(err.request);
        }
    }*/
    async function addPrinterLocationRecord(record)
    {
       
        const data = {
            id:0,
            mac_id:record.mac_id,
            location_id:record.location_id,
            printer_make_id:record.printer_make_id,
            timezone:record.timezone
        }
        
        console.log("ADD Printer location:"+ JSON.stringify(data));
        await axios.post(UrlHelperObj.printerlocationUrl(),data)
        .then(response => response.data)
        .then(results =>console.log("Add of printer "+record+" successful"))
        .catch(err => handleError(err));
        getPrinterLocationRecords();
    }
    useEffect(() =>{
        //setTableFlag(true);
        getPrinterLocationRecords();
    },[]);

    function handleFormSubmit(data,isAddOperation,isCancelOperation)
    {
        props.setFormShown(false);
        if(isCancelOperation === true)
        {
            console.log("Cancelled operation");
            return 0;
        }
        console.log("handleFormSubmit"+data+"-add-operation-"+isAddOperation);
        if(isAddOperation === true)
            addPrinterLocationRecord(data);
        else
            editPrinterLocationRecord(data);
    }
    
    
    return(
    
        <ParentComponent 
        data={printerLocationRecords}
        columns={columnInfo}
        form={PrinterLocationForm}
        handleFormSubmitFunc={handleFormSubmit}
        setFormShownFlag={props.setFormShown}
        alreadySelectedOwnRow={props.alreadySelectedOwnRow}
        deleteFunc={deletePrinterLocationRecord}
        setSelectedRow={props.setSelectedRow}
        selectedLocationRow={props.LocationRow}
        isAddDisabled={isAddDisabled}
        />
    );
            
}

