import React, { useEffect, useState ,useContext} from "react";
import {MenuItem,TextField,Grid,Paper,Typography, Button} from '@material-ui/core'
import { useUrlContext } from '../context/urlContext';
import axios from "axios";
import { errorContext } from "../Components/ErrorDisplay";
export default function BulkPassCreation()
{
    const { UrlHelperObj } = useUrlContext();
    
    
    const[selectedPromotion,setSelectedPromotion]=useState(null);
    const[promotionList,setPromotionList]=useState([]);
    const[partnerList,setPartnerList] = useState([]);
    const[brandList,setBrandList] = useState([]);
    const[selectedPartner,setSelectedPartner] = useState(null);
    const[selectedBrand,setSelectedBrand] = useState(null);
    const[csvFile,setCsvFile] = useState(null);
    const[isSubmitEnabled,setIsSubmitEnabled] = useState(true);
    const {handleError} = useContext(errorContext);
    const[bulkPassData,setBulkPassData] = useState({
        promoid:0,
        image:null
    })
    const[result,setResult] = useState("");
    useEffect(()=>{
        getPartnerList()
        setIsSubmitEnabled(false);
        //getPromoLocationList(1);
    },[]);
    async function getPartnerList()
    {
        await axios.get(UrlHelperObj.partnerUrl())
        .then(response => response.data)
        .then(results => {setPartnerList(results)})
        .catch(err => handleError(err));
    }
    async function getBrandList(id)
    {
        await axios.get(UrlHelperObj.brandUrl_GET(id))
        .then(response => response.data)
        .then(results => {setBrandList(results)})
        .catch(err => handleError(err));
    }
    async function getPromotionList(id)
    {
        await axios.get(UrlHelperObj.promotionUrl_GET(id))
        .then(response => response.data)
        .then(results => {setPromotionList(results)})
        .catch(err => handleError(err));
    }
    async function handleChangeInBrand(e)
    {
        setSelectedBrand(e.target.value);
        getPromotionList(e.target.value);
    }
     function handleChangeInPromotion(e)
    {
        setSelectedPromotion(e.target.value);
        setBulkPassData({promoid:e.target.value});
    }
    async function handleChangeInPartner(e)
    {
        setSelectedPartner(e.target.value);
        getBrandList(e.target.value);
    }
    async function handleSubmit()
    {
        if(selectedPromotion === null)
        {
            alert("Please select promotion");
            return;
        }
        setIsSubmitEnabled(false);
        const data= new FormData();
        data.append("promoid",selectedPromotion);
        data.append("image" , csvFile);
        
        await axios.post(UrlHelperObj.bulkPromotionUrl(),data,{headers: {
            'Content-Type': 'multipart/form-data'
         }})
        .then(response => response.data)
        .then(results => setResult(results.message+" - Passes will be created shortly!!"))
        .catch(err => setResult(err.response.data.message));
    }
    
    /*function handleError(err)
    {
        console.log(JSON.stringify(err));
        
        if(err.response)
        {
            props.setErrorMessage("Error.."+err.message);
        }
        else if(err.request)
        {
            if(err.message==="Network Error")
            {
                props.setErrorMessage("Network Error...Please check connection with server");
                return;
            }
            else
                props.setErrorMessage("Error...."+err.message);
        }
        else{
            
            props.setErrorMessage(err.message);
        }
            
    }*/
    function handleChangeInFile(e)
    {
        setCsvFile(e.currentTarget.files[0]);
        setIsSubmitEnabled(true);
        //setBulkPassData({image:e.target.file[0]})
    }
    function handleClear()
    {
        
        document.getElementById("image").value = "";
        setSelectedBrand(null);
        setSelectedPartner(null);
        setSelectedPromotion(null);
        setCsvFile(null);
        setResult("");
        setIsSubmitEnabled(false);
    }
    return(
        <Grid container xs={12} style={{borderStyle:'none',borderColor:'black'}} direction="row">
            <Grid container item style={{textAlign:'center',borderStyle:'none',borderColor:'green'}}>
                <Grid item xs={12}>
                <Paper><Typography variant="h5"  style={{ textAlign:'center'}} >Bulk Pass Creation</Typography></Paper>
                </Grid>
            </Grid>
            <Grid container xs={12} direction="column">
                <Grid item xs={6} style={{borderStyle:'none',borderColor:'green'}}>

                </Grid>
                <Grid item xs={6} 
                    style={{textAlign:'center', marginLeft:'80px',marginTop:'20px',
                         borderStyle:'none',borderColor:'green'}}>
                    <Typography> Passes can be created in bulk through this menu.
                        Please select the promotion for which passes will be created.
                        Please upload CSV file having name,phone number or email address of 
                        the consumer.
                     </Typography>
                </Grid>
                
            </Grid>
            <Grid item xs={5} style={{borderStyle:'none',borderColor:'green'}}></Grid>
            <Grid item container spacing={2} 
                style={{width:'230px',marginTop:'0px',borderStyle:'none',borderColor:'blue'}}>
                <Grid item  style={{ width:'230px',  borderStyle:'none',borderColor:'blue'}}>
                    <TextField 
                        fullWidth
                        variant='outlined'
                        select
                        id='selectedPartner'
                        name='selectedPartner'
                        value={selectedPartner}
                        label="Select Partner"
                        onChange={handleChangeInPartner}
                        >
                        {partnerList &&
                            partnerList.map(type => (
                            <MenuItem value={type.id} key={type.id}>
                                {type.name}
                            </MenuItem>
                            ))}
                    </TextField>
                </Grid>
                <Grid item style={{width:'230px'}} >
                    <TextField 
                        fullWidth
                        variant='outlined'
                        select
                        id='selectedBrand'
                        name='selectedBrand'
                        value={selectedBrand}
                        label="Select Brand"
                        onChange={handleChangeInBrand}
                        >
                        {brandList &&
                            brandList.map(type => (
                            <MenuItem value={type.id} key={type.id}>
                                {type.name}
                            </MenuItem>
                            ))}
                    </TextField>
                </Grid>
                <Grid item  style={{width:'230px'}}>
                    <TextField 
                        fullWidth
                        variant='outlined'
                        select
                        id='selectedPromotion'
                        name='selectedPromotion'
                        value={selectedPromotion}
                        label="Select Promotion"
                        onChange={handleChangeInPromotion}
                        >
                        {promotionList &&
                            promotionList.map(type => (
                            <MenuItem value={type.id} key={type.id}>
                                {type.name}
                            </MenuItem>
                            ))}
                    </TextField>
                </Grid>
            </Grid>
            <Grid item xs={4} style={{borderStyle:'none',borderColor:'green'}}></Grid>
            <Grid item xs={4} style={{borderStyle:'none',borderColor:'green'}}></Grid>
            <Grid item xs={5} style={{width:'200px',borderStyle:'none',borderColor:'black'}}>
                
            </Grid>
            <Grid item xs={12} style={{textAlign:'center', borderStyle:'none',borderColor:'red'}}>
                <Typography>{result}</Typography>
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}
             style={{textAlign:'center',marginLeft:'20px' ,
             marginTop:'20px',borderStyle:'none',borderColor:'green'}}>
                <label>Select CSV File</label>
                <TextField
                     fullWidth
                    variant='standard'
                    type='file'
                    label='select csv file'
                    name='image'
                    id='image'
                    accept={".csv"}
                    onChange={handleChangeInFile}
                />
            </Grid>
            <Grid item xs={3} style={{borderStyle:'none',borderColor:'green'}}></Grid>
            <Grid item xs={4} style={{borderStyle:'none',borderColor:'green'}}></Grid>
            <Grid item xs={2} style={{borderStyle:'none',borderColor:'black', marginTop:'30px',marginLeft:'30px',marginBottom:'30px'}}>
                <Button onClick={handleSubmit} disabled={!isSubmitEnabled} color="primary" variant="contained"> Submit </Button>
            </Grid>
            <Grid item style={{marginTop:'30px'}}>
                <Button onClick={handleClear} color="secondary" variant="contained"> Clear </Button>
            </Grid>
        </Grid>
    );

}