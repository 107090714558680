import {FormControl,FormControlLabel,RadioGroup,Radio, Button, Grid, MenuItem,TextField } from '@material-ui/core'
import { Autocomplete } from '@mui/material';
import React, { useEffect } from 'react'
import { useState,useContext } from 'react';
import DateFnsUtils from '@date-io/date-fns'; 
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from "axios";
import { useUrlContext } from '../context/urlContext';
import {
    
    MuiPickersUtilsProvider,
    DatePicker
} from '@material-ui/pickers';
import { errorContext } from "../Components/ErrorDisplay"

function ScanReport(props) {
    const { UrlHelperObj } = useUrlContext();
    const {handleError,setLoading,setErrorMessage} = useContext(errorContext);
    const[fromDate,setFromDate] = useState(null);
    const[toDate,setToDate] = useState(null);
    const[promoType,setPromoType] = useState(2);
    const[selectedRPartner,setSelectedRPartner] = useState(null);
    const[locationList,setLocationList] = useState([]);
    const[rpartnerList,setRpartnerList] = useState([]);
    const[scanRadioValue,setScanRadioValue] = useState(1);
    const[typeList,setTypeList]=useState([{id:2,name:"Gym"},{id:1,name:"Zoo"},{id:0,name:"General"}])
    const[productList,setProductList] = useState([]);//{id:2,name:"Gym"},{id:1,name:"Zoo"},{id:0,name:"General"}])
    const[scanTypeList,setScanTypeList] = useState([{id:1,name:"Valid"},{id:2,name:"Invalid"}])
    const[productValue,setProductValue]=useState([]);
    const[locationValue,setLocationValue]=useState([]);
    const validationSchema = yup.object({
        
        valid_to:yup
            .string("AAA")
            .nullable()
            .when('type', {
                is: (type) => (type === '1' || type === '0'),
                then: yup.string().required("Valid to date is required").nullable()
            })
        });

        const formik = useFormik({
            enableReinitialize:true,
            validationSchema: validationSchema,
            initialValues:{
                brand_id:props.selectedBrand,
                valid_from: '',
                valid_to: '',
                type:2,
                products:[],
                locations:[],
                redemption_partner_id:0,
                scan_for:"valid"
                
            },
    
            onSubmit:(values)=>{
               //alert(JSON.stringify(values));
               if(values.brand_id === null || values.brand_id === 0)
                {
                    alert("Please select Issuing partner and brand");
                    return;
                }
                postFormData(values);
            },
         
        });
        
        async function postFormData(data)
        {
            setLoading(true);
            //alert(JSON.stringify(data));
            await axios.post(UrlHelperObj.scanReportUrl(),data,
            {responseType:'blob'
            })
            .then(response => response.data)
            .then(results => {console.log("Post data Consumer report");
            const url = window.URL.createObjectURL(new Blob([results]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "download.csv"); //or any other extension
            document.body.appendChild(link);
            link.click();})
            .catch(err => handleError(err));
            setLoading(false);
        }

        useEffect(()=>{
            var arr=[];
            productValue.forEach(temp=>
                {arr.push(temp.id);}
            )
            formik.setFieldValue("products",arr);
        },[productValue]);

        useEffect(()=>{
            var arrLoc=[];
            locationValue.forEach(temp=>
                {arrLoc.push(temp.id);}
            )
            formik.setFieldValue("locations",arrLoc);
        },[locationValue]);

        useEffect(()=>{
            if(props.selectedBrand !== null || props.selectedBrand !== undefined){
           //    alert("GetProduct"+props.selectedBrand+"  "+promoType)
                getProductList(promoType);
                getRedemptionPartnerList()
            }
           //alert(props.selectedBrand);
           
       },[props.selectedBrand]);
        function handleChangeInRPartner(e){
            setSelectedRPartner(e.target.value);
            setLocationValue([]);
            formik.setFieldValue("locations",[]);
            formik.setFieldValue("redemption_partner_id",e.target.value);
            getLocations(e.target.value)
        }
        async function getLocations(locId)
        {
            await axios.get(UrlHelperObj.locationUrl_GET(locId))
        .then(response => response.data)
        .then(results => setLocationList(results))
        .catch(err => handleError(err));
        }
        async function getRedemptionPartnerList(){
            await axios.get(UrlHelperObj.rpartnerUrl())
        .then(response => response.data)
        .then(results => setRpartnerList(results))
        .catch(err => handleError(err));
        }
        const handleChangeInPromotionType = (e) =>{
            formik.setFieldValue("type",parseInt(e.target.value));
            setToDate(null);
            setFromDate(null);
            formik.setFieldValue("valid_from","");
            formik.setFieldValue("valid_to","");
        }
        async function getProductList(type)
        {
            await axios.get(UrlHelperObj.promotionUrl_GET(props.selectedBrand,type))
        .then(response => response.data)
        .then(results => setProductList(results))
        .catch(err => handleError(err));
        }
        
        function tenFunc(i){
            return(i<10?'0':'')+i;
        }
        function handleChangeFromDate(e)
        {
            setFromDate(e);
            var fromDateStr = e.getFullYear()+"-"+e.getMonth()+1+"-"+tenFunc(e.getDate());
            formik.setFieldValue("valid_from",fromDateStr);
        }
        function handleChangeToDate(e)
        {
            setToDate(e);
            var toDateStr = e.getFullYear()+"-"+e.getMonth()+1+"-"+tenFunc(e.getDate());
            formik.setFieldValue("valid_to",toDateStr);
        }
    function handleChangeInReportScanValue(e)
    {
        setScanRadioValue(parseInt(e.target.value));
        if(parseInt(e.target.value) === 1)
            formik.setFieldValue("scan_for","valid");
        else{
            //alert(e.target.value);
            formik.setFieldValue("scan_for","invalid");
        }
    }
    function handleClearButton(){
        setFromDate(null);
        setToDate(null);
        setPromoType(2);
        setSelectedRPartner(null);
        setLocationList([]);
        setScanRadioValue(1);
        setProductList([]);//{id:2,name:"Gym"},{id:1,name:"Zoo"},{id:0,name:"General"}])
        setProductValue([]);
        setLocationValue([]);
        formik.setFieldValue("scan_for","valid");
        formik.setFieldValue("valid_to","");
        formik.setFieldValue("valid_from","");
        formik.setFieldValue("locations",[]);
        formik.setFieldValue("products",[]);
        formik.setFieldValue("type",2);
    }
  return (
    // <Paper>
    <form onSubmit={formik.handleSubmit}  >
    <Grid item container xs={12}  spacing={2}
    style={{ borderStyle: 'none', borderColor: 'yellow' }}
    direction="row">

        <Grid item container xs={12}  spacing={2}
        style={{ borderStyle: 'none', borderColor: 'green' }}
        direction="row">
            
        
        <Grid item xs={1} sm={1} md={1}></Grid>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Grid item xs={4} sm={4} md={4}  >
                                    <DatePicker
                                        fullWidth
                                        autoOk={true}
                                        inputVariant='outlined'
                                        id="valid_from"
                                        name="valid_from"
                                        label="Valid from date"
                                        value={fromDate}
                                        
                                        
                                        format="dd/MM/yyyy"
                                        //value={formik.values.valid_from}
                                        //type="date"
                                        //disabled={(!isAddOperation) || (formik.values.type===2)}
                                        onChange={handleChangeFromDate}
                                        InputLabelProps={{ shrink: true }}  
                                        error={formik.touched.valid_from && Boolean(formik.errors.valid_from)}
                                        helperText={formik.touched.valid_from && formik.errors.valid_from}
                                    />
                                </Grid>
                                <Grid item xs={1} sm={1} md={1}></Grid>
                                <Grid item xs={4} sm={4} md={4}  >
                                    <DatePicker
                                        fullWidth
                                        autoOk={true}
                                        inputVariant='outlined'
                                        id="valid_to"
                                        name="valid_to"
                                        label="Valid To Date"
                                        //disabled={(!isAddOperation) || (formik.values.type===2)}
                                        value={toDate}
                                        onChange={handleChangeToDate}
                                        // value={formik.values.valid_to}
                                        // onChange={formik.handleChange}
                                        
                                        
                                        format="dd/MM/yyyy"
                                        InputLabelProps={{ shrink: true }}  
                                        error={formik.touched.valid_to && Boolean(formik.errors.valid_to)}
                                        helperText={formik.touched.valid_to && formik.errors.valid_to}
                                    />
                                </Grid>
            </MuiPickersUtilsProvider>                      
            <Grid item xs={1} sm={1} md={2}></Grid>
            <Grid item xs={1} sm={1} md={1}></Grid>
            <Grid  item xs={4} sm={4} md={4}  >
                <TextField
                variant='outlined'
                fullWidth
                //disabled={!isAddOperation}
                select
                InputLabelProps={{ shrink: true }} 
                id="type"
                name="type"
                label="   Promotion Type"
                value={formik.values.type}
                onChange={handleChangeInPromotionType}
                error={formik.touched.type && Boolean(formik.errors.type)}
                helperText={formik.touched.type && formik.errors.type}
                >
                    {typeList &&
                        typeList.map(type1 => (
                        <MenuItem value={type1.id} key={type1.id}>
                            {type1.name}
                        </MenuItem>
                        ))}
                </TextField>
            </Grid>
            <Grid item xs={1} sm={1} md={1}></Grid>
            
            <Grid item xs={4} sm={4} md={4}>
            <Autocomplete
                fullWidth
                multiple
                id="size-medium-outlined-multi"
                size="medium"
                value={productValue}
                
                onChange={(event: any, newValue: any | null) => {
                    //alert(JSON.stringify(productValue))
                    setProductValue(newValue.map(option => option.value || option));
                    }}
            isOptionEqualToValue={(option, value) => option.value === value}
            // getOptionLabel={(option) => {
            //   if (typeof option === 'number') {
            //     return productList.find(item => item.value === option)?.label;
            //   } else {
            //     return option.label;
            //   }
            // }}
                options={productList}
                getOptionLabel={(product) => product.name}
                defaultValue={[productList[0]]}
                renderInput={(params) => (
                <TextField variant='outlined' 
                {...params} helperText={"Please select partner and brand to view Promotions"}
                label="Promotions" placeholder="Promotions" />
                )}
            
            />
            </Grid>
            <Grid item xs={1} sm={1} md={1}></Grid>
            <Grid item xs={1} sm={1} md={1}></Grid>
            <Grid item xs={1} sm={1} md={1}></Grid>
            <Grid item xs={5} sm={4} md={4}  >
                        <TextField 
                                fullWidth
                                variant='outlined'
                                select
                                id='selectedRPartner'
                                name='selectedRPartner'
                                value={selectedRPartner}
                                label="Select Redemption Partner"
                                onChange={handleChangeInRPartner}
                                >
                                {rpartnerList &&
                                    rpartnerList.map(type => (
                                    <MenuItem value={type.id} key={type.id}>
                                        {type.name}
                                    </MenuItem>
                                    ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} ></Grid>
                        <Grid item  xs={4} sm={4} md={4}  >
                            <Autocomplete
                            fullWidth
                            multiple
                            id="size-medium-outlined-multi"
                            size="medium"
                            value={locationValue}
                            onChange={(event: any, newValue: any | null) => {
                                //alert(JSON.stringify(productValue))
                                setLocationValue(newValue.map(option => option.value || option));
                                }}
                            isOptionEqualToValue={(option, value) => option.value === value}
                            options={locationList}
                            getOptionLabel={(location) => location.name}
                            //defaultValue={[locationList[0]]}
                            renderInput={(params) => (
                            <TextField variant='outlined'
                              {...params} label="Locations"
                              helperText={"Please select redemption partner to view locations"} placeholder="Locations" />
                            )}/>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} ></Grid>
                        <Grid item xs={1} sm={1} md={1} ></Grid>
                        <Grid item xs={1} sm={1} md={1} ></Grid>
                        <Grid item  xs={4} sm={4} md={4} style={{ borderStyle: 'none', borderColor: 'green' }} >
                        <FormControl>
                        <RadioGroup row 
                            inputProps={{variant:'outlined'}}
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={scanRadioValue}
                            
                            onChange={handleChangeInReportScanValue}
                        >
                            {
                                  scanTypeList.map(element => (
                                    <FormControlLabel 
                                    style={{marginRight:'10px',marginLeft:'10px'}} 
                                    value={element.id} control={<Radio />} 
                                    label={element.name} />    
                                ))
                            }
                        </RadioGroup>
                        </FormControl>
                        </Grid>
        
        </Grid>
        <Grid item container direction='row'   style={{ borderStyle: 'none', borderColor: 'yellow' }} xs={12} justifyContent='center'>
                    <Grid item xs={4} md={4} sm={4} style={{ textAlign:'center', borderStyle: 'none', borderColor: 'red' }}>
                        <Button type='submit' variant='contained' color='primary'> Submit</Button>
                        {/* <Button onClick={handleClearButton} variant='contained' color='secondary'> Clear</Button> */}
                    </Grid>
        </Grid>
    </Grid>
    </form>
  )
}

export default ScanReport