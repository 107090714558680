import React, { useState } from "react";
import {
    Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  
} from "@material-ui/core";
//import useStyles  from "../theme/formCssStyles";

import { Link } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/styles";

const useStyles1 = makeStyles((theme) => ({
  drawerLink:{
    textDecoration:"none",
    color: "blue",
    fontSize: "20px",
},
icon:{
    color: "white"
},
  drawer: {
    // zIndex: 1250,
    width: 232,//theme.layout.drawerWidth
    zIndex:1000
  },
  // Styles for the `Paper` component rendered by `Drawer`.
  drawerPaper: {
    width: "inherit",
    paddingTop: 64 ,// equal to AppBar height
    zIndex:1000
  },
}));
function DrawerComponent(props)
{
    const classes = useStyles1();
    const [openDrawer, setOpenDrawer] = useState(true);
    
    // const drawer = (
    //     <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
    //       {/* <Typography variant="h6" sx={{ my: 2 }}>
    //         MUI
    //       </Typography> */}
    //       <Divider />
    //       <List>
    //         {props.navItems.map((item) => (
    //                 <ListItem>
    //                     <ListItemText>
                            
    //                         <Link to={"/"+item} >
    //                             item
    //                         </Link>
    //                     </ListItemText>    
    //                 </ListItem>
    //             ))}
    //       </List>
    //     </Box>
    //   );
    
    return (
          <>
            <Drawer
              className={classes.drawer}
              classes={{ paper: classes.drawerPaper }}
              //  style={{zIndex:1}}
              variant="permanent"
              // open={openDrawer}
              // onClose={() => setOpenDrawer(true)}
            >
              <div>
              <List>
              <ListItem onClick={() => setOpenDrawer(true)}>
                  <ListItemText>

                    <Link to="/IssuingPartner" className={classes.drawerLink}>Issuing Partner</Link>

                  </ListItemText>
                </ListItem>
                <Divider/>
                <ListItem onClick={() => setOpenDrawer(true)}>
                  <ListItemText>
                    <Link to="/RedemptionPartner" className={classes.drawerLink}>Redemption Partner</Link>
                  </ListItemText>
                </ListItem>
                <Divider/>
                <ListItem onClick={() => setOpenDrawer(true)}>
                  <ListItemText>
                    <Link to="/PrinterMake" className={classes.drawerLink}>Printer Make</Link>
                  </ListItemText>
                </ListItem>
                <Divider/>
                <ListItem onClick={() => setOpenDrawer(true)}>
                  <ListItemText>
                    <Link to="/CsvUpload" className={classes.drawerLink}>Bulk Passes</Link>
                  </ListItemText>
                </ListItem>
                <Divider/>
                <ListItem onClick={() => setOpenDrawer(true)}>
                  <ListItemText>
                    <Link to="/PromotionLocation" className={classes.drawerLink}>Link Promotion And Location</Link>
                  </ListItemText>
                </ListItem>
                <Divider/>
                <ListItem onClick={() => setOpenDrawer(true)}>
                  <ListItemText>
                    <Link to="/Reports" className={classes.drawerLink}>Reports</Link>
                  </ListItemText>
                </ListItem>
              </List>
              </div>
            </Drawer>
            <IconButton onClick={() => setOpenDrawer(!openDrawer)}className={classes.icon}>
              <MenuIcon />
            </IconButton>
          </>
        );
    

}
export default DrawerComponent;