import { Typography,Box } from "@material-ui/core";
import React from "react";
import { useUrlContext } from '../context/urlContext';

function StartPage(props){
    const { UrlHelperObj } = useUrlContext();

    return(
        <div style={{height:'600px',
            borderStyle:'none',borderColor:'blue',display:'flex',  justifyContent:'center',alignItems:'flex-start'  }}>
                <div>
                    <div style={{minHeight:'50px'}}>
                    </div>
                        <Box   sx={{width:400,height:200,textAlign:'center',alignItems:'center',
                        backgroundColor: 'white',
                        '&:hover': {
                            backgroundColor: 'primary.dark',
                            opacity: [0.9, 0.8, 0.7],
                        },}}>
                            <div  style={{ textAlign:'center'}}>
                                <img style={{width:'100%'}} src={UrlHelperObj.omniqponUrl()}></img>
                            </div>
                            
                            
                        </Box>
                        <div style={{textAlign:'center'}}>
                        <Typography  variant="h4" gutterBottom> Welcome to OmniQpon</Typography>
                        </div>
                    <div style={{minHeight:'50px',borderStyle:'none',borderColor:'blue'}}>
                    </div>
                </div>
        </div>
    );
}
export default StartPage;