import {FormHelperText, Button, Grid, TextField,MenuItem } from '@material-ui/core';
import {React, useEffect, useState} from 'react'
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from "axios";
import { useUrlContext } from '../context/urlContext';
import DateFnsUtils from '@date-io/date-fns'; 

import {
    TimePicker,
    MuiPickersUtilsProvider,
    DatePicker
} from '@material-ui/pickers';


export default function PromotionForm(props)
{
    const { UrlHelperObj } = useUrlContext();
    const[fromTime,setFromTime] = useState(null);
    const[toTime,setToTime] = useState(null);
    const[fromDate,setFromDate] = useState(null);
    const[toDate,setToDate] = useState(null);
    const[fileName,setFileName] = useState('');
    const[programList,setProgramList] = useState([]);
    const[tierList,setTierList] = useState([]);
    const[isAddOperation,setIsAddOperation] = useState(false);
    var default_type_Value = 2;    
    const[typeList,setTypeList]=useState([{id:2,name:"Gym"},{id:1,name:"Zoo"},{id:0,name:"General"}])
    const[recvValues,setRecvValues] = useState({ 
    id:'', name:'',
    brand_id:props.BrandRow.id,
    description:'',
    valid_from:'',
    valid_to:'',
    valid_from_time:'',
    valid_to_time:'',
    num_scans_allowed:'',
    num_days_allowed:'',
    promotion_text:'',
    programid:'',
    tierid:'',
    image:'',
    instructions:'',
    type:''
});
    
    function tenFunc(i){
        return(i<10?'0':'')+i;
    }
    function toUTCDateTimeString(newDate)
    {
        var monStr = tenFunc(newDate.getMonth()+1);
        var utcStr = newDate.getFullYear()+"-"+monStr+"-"
                        + tenFunc(newDate.getDate())+"T"+
                        tenFunc(newDate.getHours())
                        +":"
                        +tenFunc(newDate.getMinutes());
        return utcStr;
    }
    function getLocalDateTime(utcDate,utcTime)
    {
        const newDate = utcDate+" "+utcTime+"  UTC";
        return(new Date(newDate));
    }
    useEffect(()=>{
        getProgramList();
       if(props.selectedRow !== null )//&& valueLoaded === false)
       {
            console.log("Promotion Edit"+JSON.stringify(props.selectedRow));
            setFileName(props.selectedRow.image);
            var typeVar = 0;
          if(props.selectedRow.type === 2)
                typeVar = "Gym";
          else if(props.selectedRow.type === 1)
                typeVar="Zoo";
         else if(props.selectedRow.type === 0)
                typeVar="General";
                
            var tDate = "";
            var toDateTemp = null;
            var fromDateTemp = null;
            if(props.selectedRow.valid_from === null || 
                props.selectedRow.valid_to === null)
            {
                const d = new Date();
                tDate = d.toLocaleDateString();
                toDateTemp = getLocalDateTime(tDate,props.selectedRow.valid_to_time); 
                fromDateTemp = getLocalDateTime(tDate,props.selectedRow.valid_from_time); 
            }
            else{
                toDateTemp = getLocalDateTime(props.selectedRow.valid_to,props.selectedRow.valid_to_time); 
                fromDateTemp = getLocalDateTime(props.selectedRow.valid_from,props.selectedRow.valid_from_time); 
            } 
            setToTime( toDateTemp);
            setFromTime(fromDateTemp);

            setToDate( toDateTemp);
            setFromDate(fromDateTemp);
            
            props.selectedRow.valid_to_time = props.selectedRow.valid_to_time_str;
            props.selectedRow.valid_from_time = props.selectedRow.valid_from_time_str;
            props.selectedRow.valid_to = props.selectedRow.valid_to_date_str;
            props.selectedRow.valid_from = props.selectedRow.valid_from_date_str;
                      
            setRecvValues({
                id:props.selectedRow.id,
                name:props.selectedRow.name,
                brand_id:props.BrandRow.id,
                description:props.selectedRow.description,
                valid_from:props.selectedRow.valid_from,
                valid_to:props.selectedRow.valid_to,
                valid_from_time:props.selectedRow.valid_from_time,
                valid_to_time:props.selectedRow.valid_to_time,
                num_scans_allowed:props.selectedRow.num_scans_allowed,
                num_days_allowed:props.selectedRow.num_days_allowed,
                promotion_text:props.selectedRow.promotion_text,
                programid:props.selectedRow.programid,
                tierid:props.selectedRow.tierid,
                image:props.selectedRow.image,
                instructions:props.selectedRow.instructions,
                type:props.selectedRow.type
            });
            setIsAddOperation(false);
        }
        else
        {
            /*setToTime( new Date());
            setFromTime(new Date());

            setToDate( new Date());
            setFromDate(new Date());
*/
            setFileName("");
            setRecvValues({  
                id:'', name:'',
                brand_id:props.BrandRow.id,
                description:'',
                valid_from:'',
                valid_to:'',
                valid_from_time:'',
                valid_to_time:'',
                num_scans_allowed:'',
                num_days_allowed:'',
                promotion_text:'',
                programid:'',
                tierid:'',
                image:'',
                instructions:'',
                type:default_type_Value
            });
            setIsAddOperation(true);
        }

    },[props.selectedRow]);
    
    useEffect(()=>{
        console.log("Change in tier"+recvValues.programid);
        
        if(recvValues.programid !== null ||
             recvValues.programid !== undefined || recvValues.programid !== '')
            getTierList(recvValues.programid);
    },[recvValues.programid])
   
    useEffect(()=>{
        console.log("Change in type"+recvValues.type);
        
   
    },[recvValues.type])

    async function getProgramList()
    {
        await axios.get(UrlHelperObj.programUrl())
                    .then(res => res.data)
                    .then(results=> setProgramList(results.programs))
                    .catch(err => console.log("Error in getting program list"));
        
    }
    const handleChangeInPromotionType = (e) =>{
        formik.setFieldValue("type",e.target.value);
        setToDate(null);
        setFromDate(null);
        formik.setFieldValue("valid_from",null);
        formik.setFieldValue("valid_to",null);
    }
    const handleChangeInTierId = (e) =>{
        formik.setFieldValue("tierid",e.target.value);
    }
    const handleChangeInProgramId=(e)=>
    {
        console.log((e));
        
        formik.setFieldValue("programid",e.target.value);
        getTierList(e.target.value)
    }   
    const validationSchema = yup.object({
        type: yup
            .string()
            .required(),
        name: yup
            .string("AAA")
            .required('Promotion Name is required'),
        
        num_days_allowed:yup
            .number()
            .integer()
            .positive()
            .required("num days allowed required")
            .min(0),
        promotion_text:yup
            .string("AAA")
            .required("Promotion text is required"),
        description:yup
            .string("AAA")
            .required("description is required"),
        valid_to_time:yup
            .string("AAA")
            .required("valid to  time is required"),
        valid_from_time:yup
            .string("AAA")
            .required("valid from time is required"),
        valid_to:yup
            .string("AAA")
            .nullable()
            .when('type', {
                is: (type) => (type === '1' || type === '0'),
                then: yup.string().required("Valid to date is required").nullable()
            })
            ,
            
        valid_from:yup
            .string("AAA")
            .nullable()
            .when('type', {
                is: (type) => (type === '1' || type === '0'),
                then: yup.string().required("Valid from date is required").nullable()
            })
            ,
        num_scans_allowed:yup
            .number()
            .integer()
            .positive()
            .required("num scans is required")
            .min(0)
            .test(
                'Is positive?', 
                'ERROR: The number must be greater or equal to 0!', 
                (value) => value >= 0
              ),
        programid:yup
            .string("AAA")
            .required("Program is required"),
        tierid:yup
            .string("AAA")
            .required("tier is required"),
        instructions:yup
            .string("AAA")
            .required("instructions is required")
        
        
      },[['type','valid_to','valid_from']]);
   
    
    async function getTierList(programId)
    {
        await axios.get(UrlHelperObj.tierUrl(programId))
                    .then(res => res.data)
                    .then(results=> setTierList(results.tiers))
                    .catch(err => console.log("Error in getting tier list"));

    }
    function tenFunc(i){
        return(i<10?'0':'')+i;
    }
    function handleChangeFromTime(e)
    {
        setFromTime(e);
        var fromTimeStr = tenFunc(e.getHours())+":"+tenFunc(e.getMinutes());
        formik.setFieldValue("valid_from_time",fromTimeStr);
    }
    function handleChangeToTime(e)
    {
        setToTime(e);
        var toTimeStr = tenFunc(e.getHours())+":"+tenFunc(e.getMinutes());
        formik.setFieldValue("valid_to_time",toTimeStr);
    }
    function handleChangeFromDate(e)
    {
        setFromDate(e);
        var fromDateStr = e.getFullYear()+"-"+e.getMonth()+1+"-"+tenFunc(e.getDate());
        formik.setFieldValue("valid_from",fromDateStr);
    }
    function handleChangeToDate(e)
    {
        setToDate(e);
        var toDateStr = e.getFullYear()+"-"+e.getMonth()+1+"-"+tenFunc(e.getDate());
        formik.setFieldValue("valid_to",toDateStr);
    }
    function onCancelForm()
    {
        //alert("Cancel");
        props.handleSubmit(null,isAddOperation,true)
    }
    const formik = useFormik({
        enableReinitialize:true,
        validationSchema: validationSchema,
        initialValues:{
            id:recvValues?.id || '',
            brand_id:props.BrandRow.id,
            name:recvValues?.name || '',
            description:recvValues?.description || '',
            valid_from:recvValues?.valid_from || '',
            valid_to:recvValues?.valid_to || '',
            valid_from_time:recvValues?.valid_from_time || '',
            valid_to_time:recvValues?.valid_to_time || '',
            num_days_allowed:recvValues?.num_days_allowed || '',
            num_scans_allowed:recvValues?.num_scans_allowed || '',
            promotion_text:recvValues?.promotion_text || '',
            programid:recvValues?.programid || '',
            tierid:recvValues?.tierid || '',
            image:recvValues?.image || null,
            instructions:recvValues?.instructions || '',
            type:recvValues?.type || null
        },

        onSubmit:(values)=>{
           console.log(values.image);
            //alert("Promotion"+JSON.stringify(values));
            if( values.valid_from_time === "" || values.valid_to_time === "" ||
            values.valid_from_time === null || values.valid_to_time === null)
            {
                alert("Please check valid_from_time or valid_to_time");
                return;
            }
            if(values.type === 1 || values.type === 0)
            {
                if( (values.valid_from === "" || values.valid_to === "") )
                {
                    alert("Please check valid_from date or valid_to date");
                    return;
                }
                else if(fromDate.getTime() === toDate.getTime())
                {
                    if(fromTime.getTime() >= toTime.getTime())
                    {
                        alert("Please select correct date and time");
                        return;
                    }
                }
                else if(fromDate.getTime > toDate.getTime())
                {
                    alert("fromDate value can not be higher than toDate value")
                    return;
                }
                
            }
           
            else if(values.type ===2 )
            {
                values.valid_from = values.valid_to = "";
            }

            
            
            if(values.image === "" || values.image === undefined || values.image === null)
            {
                alert("Please select Promotion Image");
                return;
            }
            if(values.name === '')
            {
                props.handleSubmit(null,isAddOperation,true);
            }   
            else
            {
               // values.valid_from_time = values.valid_from_time_str;
               // values.valid_to_time = values.valid_to_time_str;
                props.handleSubmit(values,isAddOperation,false);
            }
     
        },
     
    });

    return(
        // <Grid style={{ borderStyle: 'solid', borderColor: 'pink' }} container item spacing={3} xs={12}
        // direction="column" alignItems='flex-start' >
            // <Paper >
            <form onSubmit={formik.handleSubmit}  >
                {/* <Grid container spacing={2}> */}
                    <Grid  item container xs={12} spacing={2}
                    justifyContent="space-around"  style={{ borderStyle: 'none', borderColor: 'black' }}
                    alignItems='center' direction="row">
                {/* <Grid container spacing={{xs:2,md:5}} direction="row" columns={{xs:4,sm:8,md:12}}> */}
                            <Grid item xs={12} sm={12} md={12}  >
                            </Grid>
                            <Grid  item xs={5} sm={4} md={4}  >
                                <TextField
                                variant='outlined'
                                fullWidth
                                disabled={!isAddOperation}
                                select
                                InputLabelProps={{ shrink: true }} 
                                id="type"
                                name="type"
                                label="   Promotion Type"
                                value={formik.values.type}
                                onChange={handleChangeInPromotionType}
                                error={formik.touched.type && Boolean(formik.errors.type)}
                                helperText={formik.touched.type && formik.errors.type}
                                >
                                    {typeList &&
                                        typeList.map(type1 => (
                                        <MenuItem value={type1.id} key={type1.id}>
                                            {type1.name}
                                        </MenuItem>
                                        ))}
                                </TextField>
                            </Grid>
                            <Grid item  xs={4} sm={4} md={4}  >
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    id="name"
                                    name="name"
                                    label="Name"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    error={formik.touched.name && Boolean(formik.errors.name)}
                                    helperText={formik.touched.name && formik.errors.name}
                                />
                            </Grid>
                            <Grid item  xs={4} sm={4} md={4}  >
                                <TextField
                                fullWidth
                                variant='outlined'
                                    id="description"
                                    name="description"
                                    label="Promotion Description"
                                    value={formik.values.description}
                                    onChange={formik.handleChange}
                                    error={formik.touched.description && Boolean(formik.errors.description)}
                                    helperText={formik.touched.description && formik.errors.description}
                                />
                            </Grid>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid item xs={4} sm={4} md={4}  >
                                <DatePicker
                                    fullWidth
                                    autoOk={true}
                                    inputVariant='outlined'
                                    id="valid_from"
                                    name="valid_from"
                                    label="Valid from date"
                                    value={fromDate}
                                    disablePast
                                    
                                    format="dd/MM/yyyy"
                                    //value={formik.values.valid_from}
                                    //type="date"
                                    disabled={(!isAddOperation) || (formik.values.type===2)}
                                    onChange={handleChangeFromDate}
                                    InputLabelProps={{ shrink: true }}  
                                    error={formik.touched.valid_from && Boolean(formik.errors.valid_from)}
                                    helperText={formik.touched.valid_from && formik.errors.valid_from}
                                />
                            </Grid>
                            <Grid item xs={4} sm={4} md={4}  >
                                <DatePicker
                                    fullWidth
                                    autoOk={true}
                                    inputVariant='outlined'
                                    id="valid_to"
                                    name="valid_to"
                                    label="Valid To Date"
                                    disabled={(!isAddOperation) || (formik.values.type===2)}
                                    value={toDate}
                                    onChange={handleChangeToDate}
                                    // value={formik.values.valid_to}
                                    // onChange={formik.handleChange}
                                    
                                    disablePast
                                    format="dd/MM/yyyy"
                                    InputLabelProps={{ shrink: true }}  
                                    error={formik.touched.valid_to && Boolean(formik.errors.valid_to)}
                                    helperText={formik.touched.valid_to && formik.errors.valid_to}
                                />
                            </Grid>
                            
                            <Grid item xs={4} sm={4} md={4}>
                                <TimePicker  ampm={false}
                                    fullWidth
                                    autoOk={true}
                                    inputVariant='outlined'
                                    id="valid_from_time"
                                    name="valid_from_time"
                                    label="Valid From Time"
                                    disabled={!isAddOperation}
                                    // defaultValue={formik.values.launch_date_time}
                                    value={fromTime}
                                    renderInput={(params) => <TextField {...params} />}
                                    onChange={handleChangeFromTime}
                                    InputLabelProps={{ shrink: true }}  
                                    error={formik.touched.valid_from_time && Boolean(formik.errors.valid_from_time)}
                                    helperText={formik.touched.valid_from_time && formik.errors.valid_from_time}
                                />
                            </Grid>
                            <Grid item xs={4} sm={4} md={4}>
                            <TimePicker   ampm={false}
                                fullWidth
                                autoOk={true}
                                inputVariant='outlined'
                                id="valid_to_time"
                                name="valid_to_time"
                                label="Valid To Time"
                                disabled={!isAddOperation}
                                // defaultValue={formik.values.launch_date_time}
                                value={toTime}//{formik.values.valid_to_time}
                                valueDefault={null}
                                onChange={handleChangeToTime}//{formik.handleChange}
                                InputLabelProps={{ shrink: true }}  
                                renderInput={(params) => <TextField {...params} />}
                                error={formik.touched.valid_to_time && Boolean(formik.errors.valid_to_time)}
                                helperText={formik.touched.valid_to_time && formik.errors.valid_to_time}
                            />
                            </Grid>
                            </MuiPickersUtilsProvider>
                            {/* <Grid item xs={4} sm={4} md={4}>
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    id="valid_to_time"
                                    name="valid_to_time"
                                    label="Valid To Time"
                                    disabled={!isAddOperation}
                                    // defaultValue={formik.values.launch_date_time}
                                    value={formik.values.valid_to_time}
                                    type="time"
                                    onChange={formik.handleChange}
                                    InputLabelProps={{ shrink: true }}  
                                    error={formik.touched.valid_to_time && Boolean(formik.errors.valid_to_time)}
                                    helperText={formik.touched.valid_to_time && formik.errors.valid_to_time}
                                />
                            </Grid> */}
                            <Grid item  xs={4} sm={4} md={4}  >
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    id="instructions"
                                    name="instructions"
                                    label="Instructions"
                                    value={formik.values.instructions}
                                    onChange={formik.handleChange}
                                    error={formik.touched.instructions && Boolean(formik.errors.instructions)}
                                    helperText={formik.touched.instructions && formik.errors.instructions}
                                />
                            </Grid>
                            
                            <Grid item  xs={4} sm={4} md={4}  >
                                <TextField
                                fullWidth
                                variant='outlined'
                                    id="num_scans_allowed"
                                    disabled={!isAddOperation}
                                    name="num_scans_allowed"
                                    label="Number of scans allowed "
                                    value={formik.values.num_scans_allowed}
                                    onChange={formik.handleChange}
                                    type="number"
                                    error={formik.touched.num_scans_allowed && Boolean(formik.errors.num_scans_allowed)}
                                    helperText={formik.touched.num_scans_allowed && formik.errors.num_scans_allowed}
                                />
                                 <FormHelperText id="num_scans_allowed">
                                   0 value implies infinite scans
                                </FormHelperText>
                            </Grid><Grid item  xs={4} sm={4} md={4}  >
                                <TextField
                                fullWidth
                                variant='outlined'
                                    id="num_days_allowed"
                                    disabled={!isAddOperation}
                                    name="num_days_allowed"
                                    label="Number of days allowed"
                                    value={formik.values.num_days_allowed}
                                    onChange={formik.handleChange}
                                    type="number"
                                    error={formik.touched.num_days_allowed && Boolean(formik.errors.num_days_allowed)}
                                    helperText={formik.touched.num_days_allowed && formik.errors.num_days_allowed}
                                />
                            </Grid>
                            <Grid item  xs={4} sm={4} md={4}  >
                                <TextField
                                fullWidth
                                variant='outlined'
                                    id="promotionText"
                                    name="promotion_text"
                                    label="Promotion Text"
                                    value={formik.values.promotion_text}
                                    onChange={formik.handleChange}
                                    error={formik.touched.promotion_text && Boolean(formik.errors.promotion_text)}
                                    helperText={formik.touched.promotion_text && formik.errors.promotion_text}
                                />
                            </Grid>
                            <Grid item xs={4} sm={4} md={4}  >
                            <div className="form-group">
                                    <label for="file">Promotion Image: </label>
                                    <input id="image" name="image" type="file" 
                                        // value={formik.values.image}
                                        accept='image/png, image/jpeg'
                                        onChange={(event) => {
                                            setFileName(event.currentTarget.files[0].name);
                                        formik.setFieldValue("image", event.currentTarget.files[0]);
                                    }} className="form-control"
                                    //  error={formik.touched.image && Boolean(formik.errors.image)}
                                    //  helperText={formik.touched.image && formik.errors.image}
                                    />
                                    { fileName }
                                    </div>
                                {/* <TextField
                                    fullWidth
                                    variant='outlined'
                                    id="image"
                                    name="image"
                                    label="Logo"
                                    type="file"
                                    // value={file}
                                    onChange={(event)=> formik.setFieldValue("image", event.currentTarget.files[0])}
                                    inputProps={{accept:"image/png, image/jpeg"}}
                                /> */}
                            </Grid>
                            <Grid  item xs={5} sm={4} md={4}  >
                                <TextField
                                variant='outlined'
                                fullWidth
                                select
                                disabled={!isAddOperation}
                                id="programid"
                                name="programid"
                                label="Program"
                                value={formik.values.programid}
                                onChange={handleChangeInProgramId}
                                error={formik.touched.programid && Boolean(formik.errors.programid)}
                                helperText={formik.touched.programid && formik.errors.programid}
                                >
                                    {programList &&
                                        programList.map(type => (
                                        <MenuItem value={type.id} key={type.id}>
                                            {type.name}
                                        </MenuItem>
                                        ))}
                                </TextField>
                                
                            </Grid>
                            <Grid  item xs={5} sm={4} md={4}  >
                                <TextField
                                variant='outlined'
                                fullWidth
                                disabled={!isAddOperation}
                                select
                                id="tierid"
                                name="tierid"
                                label="Tier"
                                value={formik.values.tierid}
                                onChange={handleChangeInTierId}
                                error={formik.touched.tierid && Boolean(formik.errors.tierid)}
                                helperText={formik.touched.tierid && formik.errors.tierid}
                                >
                                    {tierList &&
                                        tierList.map(type => (
                                        <MenuItem value={type.id} key={type.id}>
                                            {type.name}
                                        </MenuItem>
                                        ))}
                                </TextField>
                            </Grid>
                            

                    </Grid>
                    <Grid container xs={12}
                     direction="row" spacing={3}
                      style={{ borderStyle: 'none', borderColor: 'black' }} justifyContent='center'>
                        <Grid item >
                            <Button type='submit' color='primary' variant='contained'> submit </Button>
                            {/* {props.progress}
                            <CircularProgress variant="determinate" value={props.progress} /> */}
                        </Grid>
                        <Grid item>
                            <Button type='button' onClick={onCancelForm}  color='secondary' variant='contained'> cancel</Button>
                        </Grid>
                    </Grid>
                {/* </Grid> */}
            </form>
            // </Paper>
        // </Grid>


    );
}