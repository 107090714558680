import { Button, Grid, TextField,MenuItem } from '@material-ui/core';
import { useFormik } from 'formik';
import {React, useEffect, useState} from 'react'
import * as yup from 'yup';
import TimezoneSelect from 'react-timezone-select'
import axios from "axios";
import { useUrlContext } from '../context/urlContext';

export default function PrinterLocationForm(props)
{
    const { UrlHelperObj } = useUrlContext();
    const [printMakeRecords,setPrintMakeRecords] = useState([]);
    const [selectedTimezone, setSelectedTimezone] = useState( Intl.DateTimeFormat().resolvedOptions().timeZone);
    const[isAddOperation,setIsAddOperation] = useState(false);
    
    useEffect(()=>{console.log(props)},[props]);
    const[recvValues,setRecvValues] = useState({ id:'', mac_id:'',
    
    location_id:props.LocationRow.id,
    printer_make_id:''

    });
    
    async function getPrintMakeRecords()
    {
        await axios.get(UrlHelperObj.printerMakeUrl())
        .then(response => response.data)
        .then(results => setPrintMakeRecords(results))
        .catch(err => console.log(err));
    }
    useEffect(()=>{
        console.log(props)
        getPrintMakeRecords();
       if(props.selectedRow !== null )//&& valueLoaded === false)
       {
            
            setRecvValues({
                id:props.selectedRow.id,
                mac_id:props.selectedRow.mac_id,
            
                location_id:props.LocationRow.id,
                printer_make_id:props.printer_make_id
            });
            setSelectedTimezone(props.selectedRow.timezone);
            setIsAddOperation(false);
        }
        else
        {
            setRecvValues({  id:'',mac_id:'',
            location_id:props.LocationRow.id,
            
            printer_make_id:''
            });
            setIsAddOperation(true);
          
        }

    },[props.selectedRow]);
    
    const validationSchema = yup.object({
        mac_id: yup
            .string("AAA")
          .required('Mac Id is required'),
        
      });
      function onCancelForm()
      {
          //alert("Cancel");
          props.handleSubmit(null,isAddOperation,true)
      }
   
    
    const formik = useFormik({
        enableReinitialize:true,
        validationSchema: validationSchema,
        initialValues:{
            id:recvValues?.id || '',
            mac_id:recvValues?.mac_id || '',
            location_id:props.LocationRow.id,
    
            printer_make_id:recvValues?.printer_make_id || ''
        },

        onSubmit:(values)=>{
        console.log(selectedTimezone+"****");    
        //alert(JSON.stringify(values));
        values["timezone"] = selectedTimezone.value;
        //alert(JSON.stringify(values));
            if(values.mac_id === '')
                props.handleSubmit(null,isAddOperation,true);
            else
            {

                props.handleSubmit(values,isAddOperation,false);
            }
           //this.resetForm();

        },
        // onReset:(values)=>{
        //     alert("Onreset");

        //     props.handleSubmit(null,isAddOperation,true)
        // }
    });
    
    return(
        // <Grid style={{ borderStyle: 'solid', borderColor: 'pink' }} container item spacing={3} xs={12}
        // direction="column" alignItems='flex-start' >
            // <Paper >
            <form onSubmit={formik.handleSubmit} >
                {/* <Grid container spacing={2}> */}
                    <Grid  item container xs={12} spacing={2}
                    justifyContent="space-around"  style={{ borderStyle: 'none', borderColor: 'black' }}
                    alignItems='center' direction="row">
                {/* <Grid container spacing={{xs:2,md:5}} direction="row" columns={{xs:4,sm:8,md:12}}> */}
                            <Grid item xs={12} sm={12} md={12}  >
                            </Grid>
                            <Grid item  xs={4} sm={4} md={5}  >
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    id="mac_id"
                                    name="mac_id"
                                    label="Mac Id"
                                    value={formik.values.mac_id}
                                    onChange={formik.handleChange}
                                    error={formik.touched.mac_id && Boolean(formik.errors.mac_id)}
                                    helperText={formik.touched.mac_id && formik.errors.mac_id}
                                />
                            </Grid>
                            <Grid item xs={4} sm={4} md={4}>
                            <TimezoneSelect
                                value={selectedTimezone}
                                onChange={setSelectedTimezone}
                                />
                            </Grid>
                            <Grid  item xs={4} sm={4} md={4}  >
                                <TextField
                                variant='outlined'
                                fullWidth
                                select
                                id="printer_make_id"
                                name="printer_make_id"
                                label="Printer Make Id"
                                value={formik.values.printer_make_id}
                                onChange={formik.handleChange}//{(e) => handleChangeInPrinterMakeId(e)}
                                error={formik.touched.printer_make_id && Boolean(formik.errors.printer_make_id)}
                                helperText={formik.touched.printer_make_id && formik.errors.printer_make_id}
                                >
                                    {
                                        printMakeRecords && printMakeRecords.map(
                                            type => (
                                                <MenuItem value={type.id} key={type.id}>
                                                    {type.name}
                                                </MenuItem>
                                                )
                                        )
                                    }
                                    {/* {programList &&
                                        programList.map(type => (
                                        <MenuItem value={type.id} key={type.id}>
                                            {type.name}
                                        </MenuItem>
                                        ))} */}
                                </TextField>
                                
                            </Grid>
                            
                            
                            
                            
                            

                    </Grid>
                    <Grid container xs={12}
                     direction="row" spacing={3}
                      style={{ borderStyle: 'none', borderColor: 'black' }} justifyContent='center'>
                        <Grid item >
                            <Button type='submit' color='primary' variant='contained'> submit </Button>
                        </Grid>
                        <Grid item>
                            <Button type='button' onClick={onCancelForm}  color='secondary' variant='contained'> cancel</Button>
                        </Grid>
                    </Grid>
                {/* </Grid> */}
            </form>
            // </Paper>
        // </Grid>


    );
}