import { makeStyles } from '@material-ui/core/styles';

// const useStyles1 = makeStyles(theme => ({
//   container: {
//     display: 'flex',
//     flexWrap: 'wrap'
//   },
//   textField: {
//     marginLeft: theme.spacing(1),
//     marginRight: theme.spacing(1)
//   },
//   dense: {
//     marginTop: theme.spacing(2)
//   },
//   menu: {
//     width: 400
//   }
// }));

const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer  +1,
    position:"fixed"
 },
  paper1: {
    padding: theme.spacing(2)
  },
  paper2: { padding: theme.spacing(0) },
  
  tableRow: {
    height: 5,
   
  },
  tableCell: {
    padding: "0px 16px"
  },
  layout: {
    width: '1000',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
    // [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
    //   width: 1000,
    //   marginLeft: 'auto',
    //   marginRight: 'auto'
    // }
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },

  
  stepper: {
    padding: theme.spacing(3, 0, 5)
  },
  
  
  button: {
    marginTop: theme.spacing(0),
    marginLeft: theme.spacing(1),
    backgroundColor:'blue'
    
  },
    navlinks: {
      marginLeft: theme.spacing(5),
      display: "flex",
    },
    logo: {
      flexGrow: "1",
      cursor: "pointer",
    },
    link: {
      textDecoration: "none",
      color: "white",
      fontSize: "20px",
      marginLeft: theme.spacing(20),
      "&:hover": {
        color: "yellow",
        borderBottom: "1px solid white",
      },
    },
    
}));
export default useStyles;
