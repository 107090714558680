import { Button, Grid, TextField,InputAdornment } from '@material-ui/core';
import { useFormik } from 'formik';
import {React, useEffect, useState} from 'react'
import * as yup from 'yup';
import TimezoneSelect from 'react-timezone-select'

export default function ShellyLockForm(props)
{
    
    const[isAddOperation,setIsAddOperation] = useState(false);
    const[recvValues,setRecvValues] = useState({ 
        id:null,
        server_uri:'',
        auth_key:'',
        channel:'',
        location_id:props.LocationRow.id,
        off_timer:''
    });

    useEffect(()=>{
        
       if(props.selectedRow !== null )//&& valueLoaded === false)
       {
            
            setRecvValues({
                id:props.selectedRow.id,
                server_uri:props.selectedRow.server_uri,
                auth_key:props.selectedRow.auth_key,
                channel:props.selectedRow.channel,
                location_id:props.selectedRow.location_id,
                off_timer:props.selectedRow.off_timer
            });
            
            setIsAddOperation(false);
        }
        else
        {
            setRecvValues({ 
                id:null, 
                server_uri:'',
                auth_key:'',
                channel:'',
                location_id:props.LocationRow.id,
                off_timer:''
            });
            setIsAddOperation(true);
            
        }

    },[props.selectedRow]);
    
    const validationSchema = yup.object({
        id:yup
        .string("AAA")
        .max(64)
        .required("Please enter registered Shelly Id"),
        server_uri: yup
            .string("AAA")
            .matches(
                /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                'Enter correct uri! It should start with https '
            )
          .required('Cloud server URI is required'),
          auth_key: yup
          .string("AAA")
        .required('auth_key is required'),
        channel: yup
        .string("AAA")
      .required('channel is required'),
        
      });
      function onCancelForm()
      {
          //alert("Cancel");
          props.handleSubmit(null,isAddOperation,true)
      }
   
    
    const formik = useFormik({
        enableReinitialize:true,
        validationSchema: validationSchema,
        initialValues:{
            id:recvValues?.id || null,
            server_uri:recvValues?.server_uri || '',
            auth_key:recvValues?.auth_key || '',
            channel:recvValues?.channel || '',
            location_id:recvValues?.location_id || '',
            off_timer:recvValues?.off_timer || ''
            
        },

        onSubmit:(values)=>{
        
           // if(values.mac_id === '')
            //    props.handleSubmit(null,isAddOperation,true);
           // else
            //{

                props.handleSubmit(values,isAddOperation,false);
            //}
           //this.resetForm();

        },
        // onReset:(values)=>{
        //     alert("Onreset");

        //     props.handleSubmit(null,isAddOperation,true)
        // }
    });
    
    return(
        // <Grid style={{ borderStyle: 'solid', borderColor: 'pink' }} container item spacing={3} xs={12}
        // direction="column" alignItems='flex-start' >
            // <Paper >
            <form onSubmit={formik.handleSubmit} >
                {/* <Grid container spacing={2}> */}
                    <Grid  item container xs={12} spacing={2}
                    justifyContent="space-around"  style={{ borderStyle: 'none', borderColor: 'black' }}
                    alignItems='center' direction="row">
                {/* <Grid container spacing={{xs:2,md:5}} direction="row" columns={{xs:4,sm:8,md:12}}> */}
                            <Grid item xs={12} sm={12} md={12}  >
                            </Grid>
                            <Grid item  xs={4} sm={4} md={5}  >
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    id="id"
                                    name="id"
                                    label="Shelly Id"
                                    disabled={formik.values.id === null ? false :true}
                                    value={formik.values.id}
                                    onChange={formik.handleChange}
                                    // InputProps={{
                                    //     startAdornment: <InputAdornment position="start">https</InputAdornment>,
                                    //   }}
                                    error={formik.touched.id && Boolean(formik.errors.id)}
                                    helperText={formik.touched.id && formik.errors.id}
                                />
                                
                            </Grid>
                            <Grid item  xs={4} sm={4} md={5}  >
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    id="server_uri"
                                    name="server_uri"
                                    label="Cloud Server URI"
                                    value={formik.values.server_uri}
                                    onChange={formik.handleChange}
                                    // InputProps={{
                                    //     startAdornment: <InputAdornment position="start">https</InputAdornment>,
                                    //   }}
                                    error={formik.touched.server_uri && Boolean(formik.errors.server_uri)}
                                    helperText={formik.touched.server_uri && formik.errors.server_uri}
                                />
                            </Grid>
                            <Grid item  xs={4} sm={4} md={5}  >
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    id="auth_key"
                                    name="auth_key"
                                    label="Auth Key"
                                    value={formik.values.auth_key}
                                    onChange={formik.handleChange}
                                    error={formik.touched.auth_key && Boolean(formik.errors.auth_key)}
                                    helperText={formik.touched.auth_key && formik.errors.auth_key}
                                />
                            </Grid>
                            <Grid item  xs={4} sm={4} md={5}  >
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    id="channel"
                                    name="channel"
                                    label="Channel"
                                    type="number"
                                    value={formik.values.channel}
                                    onChange={formik.handleChange}
                                    error={formik.touched.channel && Boolean(formik.errors.channel)}
                                    helperText={formik.touched.channel && formik.errors.channel}
                                />
                            </Grid>
                            <Grid item  xs={4} sm={4} md={5}  >
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    id="off_timer"
                                    name="off_timer"
                                    label="Off-Timer (in Sec)"
                                    type="number"
                                    defaultValue="10"
                                    
                                    value={formik.values.off_timer}
                                    onChange={formik.handleChange}
                                    error={formik.touched.off_timer && Boolean(formik.errors.off_timer)}
                                    helperText={formik.touched.off_timer && formik.errors.off_timer}
                                />
                            </Grid>
                            
                    </Grid>
                    <Grid container xs={12}
                     direction="row" spacing={3}
                      style={{ borderStyle: 'none', borderColor: 'black' }} justifyContent='center'>
                        <Grid item >
                            <Button type='submit' color='primary' variant='contained'> submit </Button>
                        </Grid>
                        <Grid item>
                            <Button type='button' onClick={onCancelForm}  color='secondary' variant='contained'> cancel</Button>
                        </Grid>
                    </Grid>
                {/* </Grid> */}
            </form>
            // </Paper>
        // </Grid>


    );
}