import React, { useEffect,useState } from "react";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { RenderTree, data } from "./sampledata.ts";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  checkbox: {
    "&.MuiCheckbox-root": {
      color: "rgba(81, 185, 201, 0.8)"
    },
    "&.MuiCheckbox-colorSecondary": {
      "&.Mui-checked": {
        color: "rgba(160, 81, 201, 1)"
      }
    }
  }
}));

export default function RecursiveTreeView(props) {
  const [selected, setSelected] = React.useState([]);

 
  useEffect(()=>{
    console.log("USEEFFECT"+JSON.stringify(props.selectedList));
      if(props.selectedList.length > 0)
          setSelected(props.selectedList);
      else 
          setSelected([]);
      //console.log(JSON.stringify(props.selectedList));
  },[props.selectedList]);
  
  useEffect(() => {
    //console.log("*****"+JSON.stringify(selected));
  },[selected])
  //node is always the root "Parent"
  //id is id of node clicked
  function getChildById(node: RenderTree, nodeId: string) {
    let array: string[] = [];

    //returns an array of nodes ids: clicked node id and all children node ids
    function getAllChild(nodes: RenderTree | null) {
      if (nodes === null) return [];
          array.push(nodes);
      //array.push(nodes.nodeId);
      if (Array.isArray(nodes.children)) {
        nodes.children.forEach((node) => {
          array = [...array, ...getAllChild(node)];
          array = array.filter((v, i) => array.indexOf(v) === i);
        });
      }
      
      return array;
    }

    //returns the node object that was selected
    function getNodeById(nodes: RenderTree, nodeId: string) {
      if (nodes.nodeId === nodeId) {
        return nodes;
      } else if (Array.isArray(nodes.children)) {
        let result = null;
        nodes.children.forEach((node) => {
          if (!!getNodeById(node, nodeId)) {
            result = getNodeById(node, nodeId);
          }
        });
        return result;
      }

      return null;
    }

    return getAllChild(getNodeById(node, nodeId));
  }

  function getOnChange(checked: boolean, nodes: RenderTree) {
    //gets all freshly selected or unselected nodes
    props.setIsChange(true);
    const allNode = getChildById(props.data, nodes.nodeId);
    //combines newly selected nodes with existing selection
    //or filters out newly deselected nodes from existing selection
    
    // let array = checked
    //   ? [...selected, ...allNode]

    //   : selected.filter((value) => !allNode.includes(value.nodeId));

      if(checked)
        allNode[0].mapped = true;
      else
        allNode[0].mapped = false;
      
      let array = checked
      ? [...props.selectedList, ...allNode]
      :  props.selectedList.filter((value) => allNode[0].nodeId !== value.nodeId);

      console.log("CHANGE"+JSON.stringify(array));
    
      props.setSelectedList(array);
    //setSelected(array);
  }

  const RenderTreeWithCheckboxes = (nodes: RenderTree) => {
    const classes = useStyles();
    useEffect(()=>{
      console.log("#"+JSON.stringify(nodes));
    },[]);
    return (
      
      <TreeItem
        key={nodes.nodeId}
        nodeId={nodes.nodeId}
        label={
          
          <FormControlLabel
            
            control={ 
               nodes.isLeafNode ? (
               <Checkbox
           
               checked={ props.selectedList.find(item => (item.nodeId === nodes.nodeId))}
               inputProps={{ 'aria-label': 'controlled' }}
              onChange={(event) =>
                getOnChange(event.currentTarget.checked, nodes)
              }
                
                // checked={selected.some((item) => (
                //   (item === nodes.nodeId) ))}
                // onChange={(event) =>
                //   getOnChange(event.currentTarget.checked, nodes)
                // }
                
                className={classes.checkbox}
              />):(<div></div>)
            }
            label={<>{nodes.name}</>}
            key={nodes.nodeId}
          />
        }
      >
        {/* {Array.isArray(nodes.children)
          ? nodes.children.map((node) => ("*"+JSON.stringify(node)))
          : null} */}
        {Array.isArray(nodes.children)
          ? nodes.children.map((node) => RenderTreeWithCheckboxes(node))
          : null}
      </TreeItem>
    );
  };

  return (
    <>
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        
        defaultExpandIcon={<ChevronRightIcon />}  
      >
        {props.data && RenderTreeWithCheckboxes(props.data,props.selectedList)}
      </TreeView>
      
      
    </>
  );
}
