
import './App.css';

import IssuingPartner from "./Pages/IssuingPartner";
import RedemptionPartner from './Pages/RedemptionPartner';
import StartPage from './Pages/startPage';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { UrlContext } from './context/urlContext';
import UrlHelper from './Components/UrlHelperClass';
import MainLayout from './Layout/MainLayout';
import PrivateRouteWithLayout from './Layout/PrivateRouteWithLayout'
import PrinterMake from './Pages/PrinterMake';
import PromotionLocationMap from './Pages/PromotionLocationMap';
import BulkPassCreation from './Pages/BulkPassCreation';
import { ErrorDisplayProvider } from './Components/ErrorDisplay';
import Report from './Pages/Report';


let UrlHelperObj = new UrlHelper();

function App() {
  return (
    
    <Router>
      <ErrorDisplayProvider value={{
    errorMessage:'',
    loading:false
}} >
        <UrlContext.Provider value={{ UrlHelperObj }}>
        {/* <Navbar /> */}
        
        <Switch>

          <PrivateRouteWithLayout exact path="/" layout={MainLayout} component={StartPage} />
          <PrivateRouteWithLayout exact path="/IssuingPartner"  layout={MainLayout} 
                          component={IssuingPartner} />
          <PrivateRouteWithLayout exact path="/RedemptionPartner" layout={MainLayout} 
                          component={RedemptionPartner} />
          <PrivateRouteWithLayout exact path="/PrinterMake" layout={MainLayout} 
                          component={PrinterMake} />
          <PrivateRouteWithLayout exact path="/PromotionLocation" layout={MainLayout} 
                          component={PromotionLocationMap} />
          <PrivateRouteWithLayout exact path="/CsvUpload" layout={MainLayout} 
                          component={BulkPassCreation} />
          <PrivateRouteWithLayout exact path="/Reports" layout={MainLayout} 
                          component={Report} />
                          
        </Switch>
        </UrlContext.Provider>
    </ErrorDisplayProvider>
  </Router>
  
  );
}

export default App;
