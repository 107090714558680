import React, { useEffect, useState,useContext } from "react";
import {MenuItem,TextField,Grid,Paper,Typography, Button} from '@material-ui/core'
import RecursiveTreeView from "../Components/CheckedTreeView"
import axios from "axios";
import { useUrlContext } from '../context/urlContext';
import { RenderTree, data } from "../Components/sampledata.ts";
import { errorContext } from "../Components/ErrorDisplay";

function PromotionLocationMap(props)
{
    const { UrlHelperObj } = useUrlContext();
    const {handleError} = useContext(errorContext);
    const[promoLocationTreeList,setPromoLocationTreeList]=useState(null);
    const[selectedPromotion,setSelectedPromotion]=useState(null);
    const[promotionList,setPromotionList]=useState([]);
    const[partnerList,setPartnerList] = useState([]);
    const[brandList,setBrandList] = useState([]);
    const[selectedPartner,setSelectedPartner] = useState(null);
    const[selectedBrand,setSelectedBrand] = useState(null);
    const[selectedNodesInTree,setSelectedNodesInTree]= useState([]);
    const[isChange,setIsChange] = useState(false);
    useEffect(()=>{
        getPartnerList()
        
        //getPromoLocationList(1);
    },[]);
    function getAllMapped(promoLocationTreeList1){
       
            promoLocationTreeList1.forEach(element => {
                //console.log("RCV:"+JSON.stringify(element));
                if(element.mapped === true){
                    setSelectedNodesInTree(selectedNodesInTree => [...selectedNodesInTree,element]);
                    //console.log("MAPPED:"+JSON.stringify(element));
                }
                if(Array.isArray(element.children))
                {
                    getAllMapped(element.children);
                }
            });
            
        }
        
    
    useEffect(()=>{
        if(promoLocationTreeList !== null && promoLocationTreeList !== undefined )
        {
            getAllMapped(promoLocationTreeList.children);
            //console.log("getAllMapped"+(selectedNodesInTree));
        }
    },[promoLocationTreeList]);

    
    async function getPartnerList()
    {
        await axios.get(UrlHelperObj.partnerUrl())
        .then(response => response.data)
        .then(results => {setPartnerList(results)})
        .catch(err => handleError(err));
    }
    async function getBrandList(id)
    {
        await axios.get(UrlHelperObj.brandUrl_GET(id))
        .then(response => response.data)
        .then(results => {setBrandList(results)})
        .catch(err => {setBrandList([])});
    }
    async function getPromotionList(id)
    {
        await axios.get(UrlHelperObj.promotionUrl_GET(id))
        .then(response => response.data)
        .then(results => {setPromotionList(results)})
        .catch(err => {setPromotionList([])});
    }
    async function getPromoLocationList(promoid)
    {
        await axios.get(UrlHelperObj.promotionLocationUrl(promoid))
        .then(response => response.data)
        .then(results => {setPromoLocationTreeList(results); console.log(results)})
        .catch(err => handleError(err));

    }
    async function handleChangeInBrand(e)
    {
        setSelectedBrand(e.target.value);
        getPromotionList(e.target.value);
    }
     function handleChangeInPromotion(e)
    {
        if(isChange === true)
        {
            alert("Please submit changes for this promotion");
            return;
        }
        setSelectedNodesInTree([]);
        
        setSelectedPromotion(e.target.value);
        setPromoLocationTreeList(null);
        getPromoLocationList(e.target.value)
    }
    async function handleChangeInPartner(e)
    {
        setSelectedPartner(e.target.value);
        getBrandList(e.target.value);
    }
    function handleCancel()
    {
        setSelectedPartner(null);
        setSelectedBrand(null);
        setSelectedPromotion(null);
        setBrandList([]);
        setPromotionList([]);
        setSelectedNodesInTree([]);
        setPromoLocationTreeList(null);
    }
    async function handleSubmit()
    {
        //console.log("SUBMIT:"+JSON.stringify(selectedPromotion))
        //console.log("SUBMIT:"+JSON.stringify(selectedNodesInTree))
        var obj={
            promoid:selectedPromotion,
            locations:[]
        }
        selectedNodesInTree.forEach(element => {
            obj.locations.push(element);    
        });
        obj.promoId = selectedPromotion;
        
        await axios.post(UrlHelperObj.promotionLocationUrl(),obj)
        .then(response => response.data)
        .then(results =>console.log("Promotion location map successful"))
        .catch(err => handleError(err));

        console.log("SUBMIT"+JSON.stringify(selectedNodesInTree));
        setSelectedNodesInTree([]);
        console.log("SUBMIT-DATA"+JSON.stringify(promoLocationTreeList));
        setIsChange(false);
        setSelectedPromotion(-1);
        setPromoLocationTreeList(null);
    }
    return(
    <Grid container xs={12} style={{borderStyle:'none',borderColor:'black'}} direction="row">
        <Grid container item style={{textAlign:'center',borderStyle:'none',borderColor:'green'}}>
            <Grid item xs={12}>
            <Paper><Typography variant="h5"  style={{ textAlign:'center'}} >Promotion Location Mapping</Typography></Paper>
            </Grid>
        </Grid>
        <Grid container  xs={12}   direction="row">
            <Grid item  alignItems="flex-start" container xs={4} direction="column" 
               style={{  borderStyle:'none',borderColor:'blue'}}>
            <Grid item container spacing={2} 
                  style={{marginLeft:'40px',width:'230px',marginTop:'60px',borderStyle:'none',borderColor:'blue'}}>
                <Grid item  style={{ width:'230px',  borderStyle:'none',borderColor:'blue'}}>
                    <TextField 
                        fullWidth
                        variant='outlined'
                        select
                        id='selectedPartner'
                        name='selectedPartner'
                        value={selectedPartner}
                        label="Select Partner"
                        onChange={handleChangeInPartner}
                        >
                        {partnerList &&
                            partnerList.map(type => (
                            <MenuItem value={type.id} key={type.id}>
                                {type.name}
                            </MenuItem>
                            ))}
                    </TextField>
                </Grid>
                <Grid item style={{width:'230px'}} >
                    <TextField 
                        fullWidth
                        variant='outlined'
                        select
                        id='selectedBrand'
                        name='selectedBrand'
                        value={selectedBrand}
                        label="Select Brand"
                        onChange={handleChangeInBrand}
                        >
                        {brandList &&
                            brandList.map(type => (
                            <MenuItem value={type.id} key={type.id}>
                                {type.name}
                            </MenuItem>
                            ))}
                    </TextField>
                </Grid>
                <Grid item  style={{width:'230px'}}>
                    <TextField 
                        fullWidth
                        variant='outlined'
                        select
                        id='selectedPromotion'
                        name='selectedPromotion'
                        value={selectedPromotion}
                        label="Select Promotion"
                        onChange={handleChangeInPromotion}
                        >
                        {promotionList &&
                            promotionList.map(type => (
                            <MenuItem value={type.id} key={type.id}>
                                {type.name}
                            </MenuItem>
                            ))}
                    </TextField>
                </Grid>
            </Grid>
            </Grid>
            <Grid item xs={6}
             style={{height:'400px',width:'300px',overflow:'auto',marginTop:'20px',  borderStyle:'none',borderColor:'red'}}>
                <Paper  >
                {promoLocationTreeList && 
                    <RecursiveTreeView 
                            //data={data}
                            data={promoLocationTreeList}
                            selectedList={selectedNodesInTree}
                            setSelectedList={setSelectedNodesInTree}
                            isChange={isChange}
                            setIsChange={setIsChange}
                            />}
                </Paper>
            </Grid>
        </Grid>
        <Grid container direction="row" alignItems="center"   xs={12} 
              style={{borderColor:'green',borderStyle:'none'}}>
            <Grid xs={4}>

            </Grid>
            <Grid item alignItems="center" direction="row" xs={5} spacing={2}
                    container style={{marginLeft:'20px',marginTop:'30px',borderColor:'red',borderStyle:'none'}}>
                <Grid item style={{}}>
                <Button onClick={handleSubmit} disabled={!isChange?true:false} color="primary" variant="contained" >Submit</Button>
                </Grid>
                <Grid item>
                <Button onClick={handleCancel} color="secondary" variant="contained">Clear</Button>
                </Grid>
            </Grid>
            <Grid xs={3}></Grid>

        </Grid>
    </Grid>);
}
export default PromotionLocationMap;