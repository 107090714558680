const USE_LOCAL = 1;
const USE_HTTP = 0;
//"omniqpon.co.in:14013";
const serverIP ="gym.omniqpon.in";//"omniqpon.in"//:14013";//"omniqpon.in:14012";//"ubuntu@ec2-34-217-116-136.us-west-2.compute.amazonaws.com:14013";//':8080';//'125.99.72.27:3000';//'192.168.1.216:8080';////172.20.1.79//125.99.72.27:3000
const localIP = 'localhost:8000';
const varHttp = "http";
const varHttps = "https";
export default class UrlHelper {
  ip = '1';
  proto = "http";

  constructor() {
    if(USE_HTTP === 1)
      this.proto = varHttp;
    else
      this.proto = varHttps;

    if (USE_LOCAL === 0) {
      this.ip = localIP;
      
    } else {
      this.ip = serverIP;
    }
  }
  consumerReportUrl()
  {
    return `${this.proto}://${this.ip}/starcloudprnt/report/consumer`
  }
  scanReportUrl()
  {
    return `${this.proto}://${this.ip}/starcloudprnt/report/scan`
  }
  bulkPromotionUrl()
  {
    return `${this.proto}://${this.ip}/starcloudprnt/frontend/promotion/bulkconsumers`
  }
  shellyLockUrl_GET(lId)
  {
    return `${this.proto}://${this.ip}/starcloudprnt/frontend/shelly?lId=${lId}`;  
  }
  shellylockUrl(id)
  {
    if (arguments.length === 0)
        return `${this.proto}://${this.ip}/starcloudprnt/frontend/shelly`;
    else
      return `${this.proto}://${this.ip}/starcloudprnt/frontend/shelly?id=${id}`;
  }
  promotionLocationUrl(id)
  {
    if (arguments.length === 0)
        return `${this.proto}://${this.ip}/starcloudprnt/frontend/promotionlocationmap`;
    else
    return `${this.proto}://${this.ip}/starcloudprnt/frontend/promotionlocationmap?promoid=${id}`;
  }
  printerMakeUrl(id)
  {
    if (arguments.length === 0)
        return `${this.proto}://${this.ip}/starcloudprnt/frontend/pmake`;
    else if(arguments.length === 1)
        return `${this.proto}://${this.ip}/starcloudprnt/frontend/pmake?id=${id}`;
  }
  printerlocationUrl_GET(lId)
  {
    return `${this.proto}://${this.ip}/starcloudprnt/frontend/locationprinters?lId=${lId}`;  
  }
  printerlocationUrl(id) {
    if (arguments.length === 0)
      return `${this.proto}://${this.ip}/starcloudprnt/frontend/locationprinters`;
    else if(arguments.length === 1)
    return `${this.proto}://${this.ip}/starcloudprnt/frontend/locationprinters?id=${id}`;
   
  }
  locationUrl(id) {
    if (arguments.length === 0)
      return `${this.proto}://${this.ip}/starcloudprnt/frontend/locations`;
    else if(arguments.length === 1)
    return `${this.proto}://${this.ip}/starcloudprnt/frontend/locations?id=${id}`;
   
  }
  locationUrl_GET(rId)
  {
    return `${this.proto}://${this.ip}/starcloudprnt/frontend/locations?rId=${rId}`;
  }
  rpartnerUrl(id) {
    if (arguments.length === 0)
      return `${this.proto}://${this.ip}/starcloudprnt/frontend/RedemptionPartners`;
    else if(arguments.length === 1)
    return `${this.proto}://${this.ip}/starcloudprnt/frontend/RedemptionPartners?pId=${id}`;
   
  }
  partnerUrl(id) {
    if (arguments.length === 0)
      return `${this.proto}://${this.ip}/starcloudprnt/frontend/partners`;
    else if(arguments.length === 1)
    return `${this.proto}://${this.ip}/starcloudprnt/frontend/partners?id=${id}`;
   
  }

  brandUrl(id){
    if(arguments.length === 0)
      return `${this.proto}://${this.ip}/starcloudprnt/frontend/brands`;
    else if(arguments.length === 1 )
      return `${this.proto}://${this.ip}/starcloudprnt/frontend/brands?id=${id}`;
  }
  
  brandUrl_GET(partnerId) {
    if (arguments.length === 1)
    return `${this.proto}://${this.ip}/starcloudprnt/frontend/brands?iId=${partnerId}`;
  // else if(arguments.length === 2)
  // return `${this.proto}://${this.ip}/starcloudprnt/frontend/brands?id=${id}`;
 
  }
  promotionUrl(id){
    if(arguments.length === 0)
      return `${this.proto}://${this.ip}/starcloudprnt/frontend/promotion`;
    else if(arguments.length === 1 )
      return `${this.proto}://${this.ip}/starcloudprnt/frontend/promotion?id=${id}`;
  }
  
  promotionUrl_GET(partnerId,type) {
    if (arguments.length === 1)
    return `${this.proto}://${this.ip}/starcloudprnt/frontend/promotion?bId=${partnerId}`;
   else if(arguments.length === 2)
     return `${this.proto}://${this.ip}/starcloudprnt/frontend/promotion?bId=${partnerId}&type=${type}`;
 
  }
  
  tierUrl(programId){
    if(arguments.length === 1)
      return `${this.proto}://${this.ip}/starcloudprnt/frontend/program/tiers?program_id=${programId}`
  }
  omniqponUrl()
  {
    return `${this.proto}://${this.ip}/starcloudprnt/frontend/oqimage`;
  }
  programUrl(){
    if(arguments.length === 0)
    {
      return `${this.proto}://${this.ip}/starcloudprnt/frontend/programs`;
    }
  }
}