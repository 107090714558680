import React,{useContext,useEffect,useState}  from 'react';

import { useUrlContext } from '../context/urlContext';

import { errorContext } from "../Components/ErrorDisplay";
import ParentComponent from "../Components/ParentForTableAndFormComponent";
import axios from "axios";
import ShellyLockForm from "../Forms/ShellyLockForm";


function ShellyLock(props)
{


    const { UrlHelperObj } = useUrlContext();
    const [columnInfo, setColumnInfo] = useState([
        {  title: 'Shelly-Id',dbname:'id' },
        {  title: 'URI',dbname:'server_uri' },
        {title:"Auth Key",dbname:"auth_key"},
        {title:"Channel",dbname:"channel"},
        {title:"Off-Timer",dbname:"off_timer"}

    ]);
    const[selectedLocation,setSelectedLocation] = useState(null);
    const[shellyRecords,setShellyRecords]=useState([]);
    const[isAddDisabled,setIsAddDisabled] = useState(true);
    const {handleError} = useContext(errorContext);

    useEffect(()=>{setSelectedLocation(props.LocationRow);
    },[props.LocationRow]);

    useEffect(()=>{getShellyRecords();},[selectedLocation]);

    useEffect(()=>{
        
        if(shellyRecords.length === 1)
            setIsAddDisabled(true);
        else
            setIsAddDisabled(false);

    },[shellyRecords]);

    async function getShellyRecords()
    {
        await axios.get(UrlHelperObj.shellyLockUrl_GET(selectedLocation.id))
        .then(response => response.data)
        .then(results => setShellyRecords(results))
        .catch(err => handleError(err));
    }
    async function deleteShellyRecord(record)
    {
        await axios.delete(UrlHelperObj.shellylockUrl(record))
        .then(response => response.data)
        .then(results =>console.log("delete of Printer Location "+record+" successful"))
        .catch(err => handleError(err));
        getShellyRecords();
    }
    async function editShellyRecord(record)
    {
        const data = {
            id:record.id,
            location_id:record.location_id,
            server_uri:record.server_uri,
            auth_key:record.auth_key,
            channel:record.channel,
            off_timer:record.off_timer
        }
        
        
        await axios.put(UrlHelperObj.shellylockUrl(),data)
        .then(response => response.data)
        .then(results =>console.log("Edit of shelly location "+record+" successful"))
        .catch(err => handleError(err));
        getShellyRecords();
    }
    /*function handleError(err)
    {
        setLoading(false);
        if(err.response)
        {
            console.log(err.response);
            setErrorMessage(err.response.data.message);
        }
        else if(err.request)
        {
            console.log(err.request);
        }
    }*/
    async function addShellyRecord(record)
    {
       console.log(JSON.stringify(record));
        const data = {
            id:record.id,
            
            location_id:record.location_id,
            server_uri:record.server_uri,
            auth_key:record.auth_key,
            channel:record.channel,
            off_timer:record.off_timer
        }
        
        console.log("ADD Shelly:"+ JSON.stringify(data));
        await axios.post(UrlHelperObj.shellylockUrl(),data)
        .then(response => response.data)
        .then(results =>console.log("Add of shelly "+record+" successful"))
        .catch(err => handleError(err));
        getShellyRecords();
        
    }
    

    function handleFormSubmit(data,isAddOperation,isCancelOperation)
    {
        props.setFormShown(false);
        if(isCancelOperation === true)
        {
            console.log("Cancelled operation");
            return 0;
        }
        console.log("handleFormSubmit"+data+"-add-operation-"+isAddOperation);
        if(isAddOperation === true)
            addShellyRecord(data);
        else
            editShellyRecord(data);
    }
    

    return(
        <ParentComponent 
        data={shellyRecords}
        columns={columnInfo}
        form={ShellyLockForm}
        handleFormSubmitFunc={handleFormSubmit}
        setFormShownFlag={props.setFormShown}
        alreadySelectedOwnRow={props.alreadySelectedOwnRow}
        deleteFunc={deleteShellyRecord}
        setSelectedRow={props.setSelectedRow}
        selectedLocationRow={props.LocationRow}
        isAddDisabled={isAddDisabled}
        />
    );
}
export default ShellyLock;