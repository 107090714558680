import { Button, Grid, TextField} from '@material-ui/core';
import { useFormik } from 'formik';
import {React, useEffect, useState} from 'react'
import * as yup from 'yup';

export default function BrandForm(props)
{
    const[isAddOperation,setIsAddOperation] = useState(false);
    const[recvValues,setRecvValues] = useState({ id:'', brandName:'',
    });

    useEffect(()=>{


        if(props.selectedRow !== null )//&& valueLoaded === false)
        {
            console.log(props.IssuingPartnerRow);
             console.log("Edit"+JSON.stringify(props.selectedRow));
             setRecvValues({
                 id:props.selectedRow.id,
                 brandName:props.selectedRow.name,
                 issuerId:props.IssuingPartnerRow.id
             });
             setIsAddOperation(false);
         }
         else
         {
            console.log(props.IssuingPartnerRow);
             setRecvValues({  id:'',brandName:'',issuerId:props.IssuingPartnerRow.id
             });
             setIsAddOperation(true);
         }
 
     },[props.selectedRow]);
     function onCancelForm()
      {
          //alert("Cancel");
          props.handleSubmit(null,isAddOperation,true)
      }
     const validationSchema = yup.object({
         brandName: yup
             .string("AAA")
           .required('brand name is required'),
         
       });
       const formik = useFormik({
        enableReinitialize:true,
        validationSchema: validationSchema,
        initialValues:{
            id:recvValues?.id || '',
            brandName:recvValues?.brandName || '',
            issuerId:props.IssuingPartnerRow.id
          
        },

        onSubmit:(values)=>{
           
            if(values.brandName === '')
                props.handleSubmit(null,isAddOperation,true);
            else
            {

                props.handleSubmit(values,isAddOperation,false);
            }
           

        },
        
    });

    return(
        // <Grid style={{ borderStyle: 'solid', borderColor: 'pink' }} container item spacing={3} xs={12}
        // direction="column" alignItems='flex-start' >
            // <Paper >
            <form onSubmit={formik.handleSubmit} >
                {/* <Grid container spacing={2}> */}
                    <Grid  item container xs={12} spacing={2}
                    justifyContent="space-around"  style={{ borderStyle: 'none', borderColor: 'black' }}
                    alignItems='center' direction="row">
                {/* <Grid container spacing={{xs:2,md:5}} direction="row" columns={{xs:4,sm:8,md:12}}> */}
                            <Grid item xs={12} sm={12} md={12}  >
                            </Grid>
                            <Grid item  xs={4} sm={4} md={5}  >
                                <TextField
                                    fullWidth
                                    disabled="true"
                                    variant='outlined'
                                    id="issuerpartnerName"
                                    name="issuerpartnerName"
                                    label="Issuing Partner Name"
                                    value={props.IssuingPartnerRow.name}
                                    // onChange={formik.handleChange}
                                    // error={formik.touched.partnerName && Boolean(formik.errors.partnerName)}
                                    // helperText={formik.touched.partnerName && formik.errors.partnerName}
                                />
                            </Grid>
                            <Grid item  xs={4} sm={4} md={5}  >
                                <TextField
                                fullWidth
                                variant='outlined'
                                    id="brandName"
                                    name="brandName"
                                    label=" brand Name"
                                    value={formik.values.brandName}
                                    onChange={formik.handleChange}
                                    error={formik.touched.brandName && Boolean(formik.errors.brandName)}
                                    helperText={formik.touched.brandName && formik.errors.brandName}
                                />
                            </Grid>
                    </Grid>
                    <Grid container xs={12}
                     direction="row" spacing={3}
                      style={{ borderStyle: 'none', borderColor: 'black' }} justifyContent='center'>
                        <Grid item >
                            <Button type='submit' color='primary' variant='contained'> submit </Button>
                        </Grid>
                        <Grid item>
                            <Button type='button' onClick={onCancelForm}  color='secondary' variant='contained'> cancel</Button>
                        </Grid>
                    </Grid>
            </form>
    )

}